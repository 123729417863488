import React, { useState, useEffect } from 'react'
import { Button, Box, Typography, IconButton, Stepper, Step, StepLabel } from '@mui/material'
import { DarkMode, LightMode, NavigateBefore, NavigateNext } from '@mui/icons-material'
import { authService } from './auth.service'
import { useAuth } from '../auth-context'
import KitchenTools from '../profile/kitchen-tools'
import DietaryRequirements from '../profile/dietary-preferences'
import Allergies from '../profile/allergy-intolerance'
import MaxMinutesPerMeal from '../common/components/max-minutes-per-meal'
import SkillLevelSelect from '../profile/skill-level'
import { userProfileService } from '../profile/user-profile.service'
import useUserPreference from '../profile/useUserPreference.hook'
import { UserPreference } from 'common'

type VerifyEmailProps = {
  isLightMode: boolean
  handleDarkLightModeChange: () => void
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({ isLightMode, handleDarkLightModeChange }) => {
  const [token, setToken] = useState('')
  const [resendCooldown, setResendCooldown] = useState(0)
  const { refreshUserStatus } = useAuth()
  const { userPreference, setUserPreference } = useUserPreference()
  const [isEmailVerifiedLocal, setIsEmailVerifiedLocal] = useState(false)

  const [activeStep, setActiveStep] = useState(0) // For onboarding steps
  const steps = [
    'Kitchen Tools',
    'Dietary Preferences',
    'Allergies',
    'Skill Level',
    'Cooking time per meal',
  ]

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (resendCooldown > 0) {
      interval = setInterval(() => {
        setResendCooldown((prev) => prev - 1)
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [resendCooldown])

  const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= 6) {
      setToken(e.target.value)
    }
  }

  const handleSubmit = async () => {
    await authService.verifyEmail({ token })
    setIsEmailVerifiedLocal(true)
  }

  const handleResend = () => {
    setResendCooldown(10)
    authService.sendEmailVerification()
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      const next = prevActiveStep + 1

      if (next === steps.length) {
        refreshUserStatus()
      }

      return next
    })
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleUpdateUserPreference = async (userPreference: Partial<UserPreference>) => {
    const userProfile = await userProfileService.update({ userPreference })
    setUserPreference(userProfile)
  }

  if (!isEmailVerifiedLocal) {
    return (
      <Box sx={{ pt: 10, textAlign: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
            <Button
              sx={{ textTransform: 'none' }}
              onClick={() => {
                authService.logout({ refreshUserStatus })
              }}
            >
              Logout
            </Button>
            <IconButton onClick={handleDarkLightModeChange}>
              {isLightMode ? <DarkMode /> : <LightMode />}
            </IconButton>
          </Box>
        </Box>
        <Typography variant='h4'>Verify Your Email</Typography>
        <Typography sx={{ mt: 2 }}>Please enter the 6-digit token sent to your email.</Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <input
            type='text'
            pattern='\d*'
            maxLength={6}
            value={token}
            onChange={handleTokenChange}
            style={{
              fontSize: '2rem',
              textAlign: 'center',
              width: '150px',
              padding: '10px',
              border: '2px solid #aaa',
              borderRadius: '4px',
            }}
          />
        </Box>
        <Box>
          <Button
            variant='contained'
            color='primary'
            sx={{ mt: 4, textTransform: 'none' }}
            onClick={handleSubmit}
            disabled={token.length !== 6}
          >
            Submit
          </Button>
        </Box>
        <Box>
          <Button
            color='primary'
            sx={{ mt: 2, textTransform: 'none' }}
            onClick={handleResend}
            disabled={resendCooldown > 0}
          >
            {resendCooldown > 0 ? `Can resend in ${resendCooldown} seconds` : 'Resend Code'}
          </Button>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        pl: 1,
        pr: 1,
        pt: 10,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: '1200px',
        mx: 'auto',
      }}
    >
      <Typography sx={{ mt: 2 }}>
        Let&apos;s get you set up! We&apos;ll collect some information to help us personalize your
        experience
      </Typography>

      <Stepper activeStep={activeStep} orientation='horizontal' sx={{ mt: 4, width: '100%' }}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{activeStep === index && label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 4, width: '100%' }}>
        {activeStep === 0 && (
          <KitchenTools
            kitchenTools={userPreference?.kitchenTools || []}
            onChange={(val) => handleUpdateUserPreference({ kitchenTools: val })}
          />
        )}
        {activeStep === 1 && (
          <DietaryRequirements
            dietaryRequirements={userPreference?.dietaryRequirements || []}
            onChange={(val) => handleUpdateUserPreference({ dietaryRequirements: val })}
          />
        )}
        {activeStep === 2 && (
          <Allergies
            allergies={userPreference?.allergies || []}
            onChange={(val) => handleUpdateUserPreference({ allergies: val })}
          />
        )}
        {activeStep === 3 && (
          <SkillLevelSelect
            skillLevel={userPreference?.skillLevel || 'beginner'}
            onChange={(val) => handleUpdateUserPreference({ skillLevel: val })}
          />
        )}
        {activeStep === 4 && (
          <MaxMinutesPerMeal
            maxMinutes={userPreference?.maxMinutesPerMeal || null}
            onChange={(val) => handleUpdateUserPreference({ maxMinutesPerMeal: val })}
          />
        )}
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Button onClick={handleBack} sx={{ textTransform: 'none' }} disabled={activeStep === 0}>
              <NavigateBefore /> Back
            </Button>
            <Button onClick={handleNext} sx={{ textTransform: 'none', ml: 2 }}>
              {activeStep === 4 ? 'Finish' : 'Next'} <NavigateNext />
            </Button>
          </Box>
        </Box>
      </Box>

      {activeStep === steps.length && (
        <Typography sx={{ mt: 2 }}>
          All set! You can always update these preferences in your profile settings.
        </Typography>
      )}
    </Box>
  )
}

export default VerifyEmail
