import { SxProps } from '@mui/material'
import React from 'react'
import ResponsiveNumberInput from '../custom-material-ui/responsive-number-input'

type ServesProps = {
  sx?: SxProps
  serves: number
  onChange: (value: number) => void
  label?: string
}

const Serves: React.FC<ServesProps> = ({
  sx,
  serves,
  onChange,
  label = 'How many people are you cooking for?',
}) => {
  return (
    <ResponsiveNumberInput
      sx={sx}
      leadingLabel={label}
      value={serves}
      onChange={onChange}
      minValue={1}
    />
  )
}

export default Serves
