import { httpService } from '../services/http.service'

const get = async () => {
  const { user } = await httpService.post({
    url: '/get-user',
  })

  return user
}

const update = async ({ name }: { name: string }) => {
  const { user } = await httpService.post({
    url: '/update-user',
    data: { name },
  })

  return user
}

export const userService = {
  get,
  update,
}
