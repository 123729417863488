import React, { useState } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardMedia,
  IconButton,
  CardHeader,
  CircularProgress,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import './loading-large-recipe-card.css'

type LargeRecipeCardProps = {
  name?: string
  description?: string
  ingredients?: string[]
  kitchenTools?: string[]
  instructions?: string[]
  calories?: number
  carbs?: number
  protein?: number
  fat?: number
  fiber?: number
  sugar?: number
  prepTime?: number
  difficulty?: 'easy' | 'medium' | 'hard'
  serves?: number
  url?: string
  cardRef?: React.Ref<HTMLDivElement>
}

const LoadingLargeRecipeCard: React.FC<LargeRecipeCardProps> = ({
  name,
  description,
  ingredients,
  kitchenTools,
  instructions,
  calories,
  carbs,
  protein,
  fat,
  fiber,
  sugar,
  prepTime,
  difficulty,
  serves,
  url,
  cardRef,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Card
      ref={cardRef}
      sx={{
        width: '100%',
        maxWidth: 1024,
        borderRadius: 3,
        boxShadow: 2,
        '&:hover': {
          boxShadow: 10,
          cursor: 'pointer',
        },
      }}
    >
      <div style={{ position: 'relative' }}>
        {!url && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: isExpanded ? '1024px' : '240px',
            }}
          >
            <CircularProgress size={40} color='primary' />
          </div>
        )}

        {url && (
          <CardMedia
            component='img'
            height={isExpanded ? '1024' : '240'}
            image={url}
            alt={name}
            style={{ width: '100%', display: 'block' }}
          />
        )}

        {!isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandMore color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
        {isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandLess color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
      </div>

      <CardContent>
        <Typography variant='h4' component='div' sx={{ fontWeight: 'bold' }}>
          <CSSTransition in={!!name} timeout={500} classNames='fade'>
            {() => (
              <span
                style={{
                  display: 'inline-block', // Consider this to keep the space when opacity is 0
                }}
              >
                {name}
              </span>
            )}
          </CSSTransition>
        </Typography>
        <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
          <CSSTransition in={!!description} timeout={500} classNames='fade'>
            {() => (
              <span
                style={{
                  display: 'inline-block', // Consider this to keep the space when opacity is 0
                }}
              >
                {description}
              </span>
            )}
          </CSSTransition>
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={4}>
            <Typography color='text.secondary' sx={{ display: 'flex', alignItems: 'center' }}>
              <CSSTransition in={!!prepTime} timeout={500} classNames='fade'>
                {() => (
                  <span
                    style={{
                      display: 'inline-block', // Consider this to keep the space when opacity is 0
                    }}
                  >
                    {prepTime && `Time: ${prepTime} minutes`}
                  </span>
                )}
              </CSSTransition>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary' sx={{ display: 'flex', alignItems: 'center' }}>
              <CSSTransition in={!!difficulty} timeout={500} classNames='fade'>
                {() => (
                  <span
                    style={{
                      display: 'inline-block', // Consider this to keep the space when opacity is 0
                    }}
                  >
                    {difficulty && `Difficulty: ${difficulty}`}
                  </span>
                )}
              </CSSTransition>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary' sx={{ display: 'flex', alignItems: 'center' }}>
              <CSSTransition in={!!serves} timeout={500} classNames='fade'>
                {() => (
                  <span
                    style={{
                      display: 'inline-block', // Consider this to keep the space when opacity is 0
                    }}
                  >
                    {serves && `Serves: ${serves}`}
                  </span>
                )}
              </CSSTransition>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={6}>
            <Card
              variant='outlined'
              sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
            >
              <CardHeader title='Ingredients' />
              <CardContent>
                {ingredients && (
                  <ul>
                    <TransitionGroup>
                      {ingredients.map((ingredient, index) => (
                        <CSSTransition key={index} timeout={500} classNames='fade'>
                          <li key={index}>{ingredient}</li>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  </ul>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              variant='outlined'
              sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
            >
              <CardHeader title='Kitchen tools needed' />
              <CardContent>
                {kitchenTools && (
                  <ul>
                    <TransitionGroup>
                      {kitchenTools.map((tool, index) => {
                        return (
                          <CSSTransition key={index} timeout={500} classNames='fade'>
                            <li key={index}>{tool}</li>
                          </CSSTransition>
                        )
                      })}
                    </TransitionGroup>
                  </ul>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Card
            variant='outlined'
            sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
          >
            <CardHeader title='Instructions' />
            <CardContent>
              {instructions && (
                <ol>
                  <TransitionGroup>
                    {instructions.map((instruction, index) => (
                      <CSSTransition key={index} timeout={500} classNames='fade'>
                        <li key={index}>{instruction}</li>
                      </CSSTransition>
                    ))}
                  </TransitionGroup>
                </ol>
              )}
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <Card
            variant='outlined'
            sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
          >
            <CardHeader title='Nutrition' />
            <CardContent>
              <ul>
                {[
                  { label: 'Calories', value: calories },
                  { label: 'Carbs', value: carbs },
                  { label: 'Protein', value: protein },
                  { label: 'Fat', value: fat },
                  { label: 'Fiber', value: fiber },
                  { label: 'Sugar', value: sugar },
                ].map((item) => (
                  <li key={item.label}>
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                      <CSSTransition
                        in={typeof item.value === 'number'}
                        timeout={500}
                        classNames='fade'
                        unmountOnExit
                      >
                        <span style={{ display: 'inline-block' }}>
                          {`${item.label}: ${item.value}`}
                        </span>
                      </CSSTransition>
                    </Typography>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Box>
      </CardContent>
    </Card>
  )
}

export default LoadingLargeRecipeCard
