import React, { useState } from 'react'
import {
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  ListItemText,
  Divider,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import {
  ExpandLess,
  ExpandMore,
  Settings,
  VpnKey,
  Payment,
  Kitchen,
  Fastfood,
  LocalHospital,
  Star,
  Timer,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { authService } from '../login/auth.service'
import { useAuth } from '../auth-context'
import useUser from '../profile/useUser.hook'

const TopBarMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openPreferences, setOpenPreferences] = useState(false)

  const { user } = useUser()

  const { refreshUserStatus } = useAuth()
  const nav = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (goto?: string) => {
    setAnchorEl(null)
    goto && nav(goto)
  }

  const handlePreferencesClick = () => {
    setOpenPreferences(!openPreferences)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    authService.logout({ refreshUserStatus })
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <Avatar alt={user?.name || user?.email} src='/path-to-user-avatar.jpg' />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose()}>
        <MenuItem onClick={() => handleClose('/profile/account')}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Account
        </MenuItem>
        <MenuItem onClick={() => handleClose('/profile/password')}>
          <ListItemIcon>
            <VpnKey />
          </ListItemIcon>
          Password
        </MenuItem>
        <MenuItem onClick={() => handleClose('/profile/subscription')}>
          <ListItemIcon>
            <Payment />
          </ListItemIcon>
          Subscription
        </MenuItem>

        <MenuItem onClick={handlePreferencesClick}>
          <ListItemText primary='Personal Preferences' />
          {openPreferences ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
        <Collapse in={openPreferences} timeout='auto' unmountOnExit>
          <List component='div' disablePadding dense>
            <ListItemButton onClick={() => handleClose('/profile/kitchenTools')}>
              <ListItemIcon>
                <Kitchen />
              </ListItemIcon>
              <ListItemText primary='Kitchen Tools' />
            </ListItemButton>
            <ListItemButton onClick={() => handleClose('/profile/dietaryPreferences')}>
              <ListItemIcon>
                <Fastfood />
              </ListItemIcon>
              <ListItemText primary='Dietary Preferences' />
            </ListItemButton>
            <ListItemButton onClick={() => handleClose('/profile/allergies')}>
              <ListItemIcon>
                <LocalHospital />
              </ListItemIcon>
              <ListItemText primary='Allergies' />
            </ListItemButton>
            <ListItemButton onClick={() => handleClose('/profile/skillLevel')}>
              <ListItemIcon>
                <Star />
              </ListItemIcon>
              <ListItemText primary='Skill Level' />
            </ListItemButton>
            <ListItemButton onClick={() => handleClose('/profile/cookingTime')}>
              <ListItemIcon>
                <Timer />
              </ListItemIcon>
              <ListItemText primary='Cooking time per meal' />
            </ListItemButton>
          </List>
        </Collapse>

        <Divider />
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default TopBarMenu
