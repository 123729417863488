import React, { useEffect, useState } from 'react'
import { InsertDriveFile } from '@mui/icons-material'
import { Box, useTheme } from '@mui/system'
import { useMediaQuery } from '@mui/material'

const FilePreview = ({ file }: { file: File }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const isImage = file.type.startsWith('image/')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (isImage) {
      const url = URL.createObjectURL(file)
      setImageUrl(url)

      // Cleanup function: Revoke the blob URL
      return () => {
        URL.revokeObjectURL(url)
      }
    }
  }, [file, isImage])

  const filePreviewStyle = {
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%', // ensures it takes the full width of its parent
  } as const

  const imagePreviewStyle = {
    maxWidth: '100%',
    width: isSmallScreen ? '100px' : '300px',
    height: isSmallScreen ? '100px' : '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    flexShrink: 0, // ensures that the image doesn't shrink
  } as const

  const fileIconStyle = {
    fontSize: '24px',
    color: '#666',
  } as const

  const fileNameStyle = {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '100%',
    flexGrow: 1, // allows the text to take up as much space as it needs
  } as const

  return (
    <Box sx={filePreviewStyle}>
      {isImage && imageUrl ? (
        <>
          <img src={imageUrl} alt={`Preview of ${file.name}`} style={imagePreviewStyle} />
          <span style={{ ...fileNameStyle }}>{file.name}</span>
        </>
      ) : (
        <>
          <InsertDriveFile style={fileIconStyle} />
          <span style={fileNameStyle}>{file.name}</span>
          <span style={fileNameStyle}>{file.type}</span> {/* Optionally display file type */}
        </>
      )}
    </Box>
  )
}

export default FilePreview
