import React from 'react'
import { List, ListItem, ListItemText, Typography, Paper, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const AdminDashboard = () => {
  const navigate = useNavigate()

  const handleNavigation = (path: string) => {
    navigate(path)
  }

  const adminOptions = [
    { name: 'Feedback', path: '/admin/feedback' },
    // Add more options here in the future.
  ]

  return (
    <Paper elevation={3} style={{ maxWidth: '400px', margin: '40px auto', padding: '20px' }}>
      <Typography variant='h4' style={{ textAlign: 'center', marginBottom: '20px' }}>
        Admin Dashboard
      </Typography>
      <Divider />
      <List component='nav'>
        {adminOptions.map((option, index) => (
          <ListItem button key={index} onClick={() => handleNavigation(option.path)}>
            <ListItemText primary={option.name} />
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}

export default AdminDashboard
