import React, { createContext, useContext, useState, useEffect, PropsWithChildren } from 'react'
import { useMediaQuery } from '@mui/material'

interface SidebarContextType {
  isLargeScreen: boolean
  isSidebarOpen: boolean
  setIsSidebarOpen: (open: boolean) => void
}

const SidebarContext = createContext<SidebarContextType | undefined>(undefined)

export const useSidebar = () => {
  const context = useContext(SidebarContext)
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider')
  }
  return context
}

export const SidebarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isLargeScreen = useMediaQuery('(min-width:1100px)')
  const [isOpen, setIsOpen] = useState(isLargeScreen)

  useEffect(() => {
    setIsOpen(isLargeScreen)
  }, [isLargeScreen])

  const setIsSidebarOpen = (open: boolean) => {
    setIsOpen(open)
    const event = new CustomEvent('sidebar-toggle')
    window.dispatchEvent(event)
  }

  return (
    <SidebarContext.Provider value={{ isSidebarOpen: isOpen, setIsSidebarOpen, isLargeScreen }}>
      {children}
    </SidebarContext.Provider>
  )
}
