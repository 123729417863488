import { Comment } from 'common'
import React, { useState, useCallback } from 'react'
import { Button, Typography, Box } from '@mui/material'
import moment from 'moment'
import ResponsiveTextField from '../../common/custom-material-ui/responsive-text-field'
import ViewRichText from '../../common/view-rich-text'

type FeedbackCommentProps = {
  comment: Comment
  onEdit: (commentId: string, newText: string) => void
  onDelete: (commentId: string) => void
}

const FeedbackComment: React.FC<FeedbackCommentProps> = ({ comment, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [editedComment, setEditedComment] = useState(comment.content)

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleSave = useCallback(() => {
    onEdit(comment.id, editedComment)
    setIsEditing(false)
  }, [onEdit, comment.id, editedComment])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedComment(e.target.value)
  }, [])

  const timeAgo = moment(comment.createdAt).fromNow()

  return (
    <Box my={2} p={2} border={1} borderColor='divider' borderRadius={2}>
      {isEditing ? (
        <Box>
          <ResponsiveTextField multiline value={editedComment} onChange={handleChange} fullWidth />
          <Box display='flex' justifyContent='flex-end' mt={1}>
            <Button
              variant='text'
              color='primary'
              onClick={handleSave}
              style={{ textTransform: 'none', marginRight: '8px' }}
            >
              Save
            </Button>
            <Button
              variant='text'
              onClick={() => setIsEditing(false)}
              style={{ textTransform: 'none' }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      ) : (
        <Box display='flex' flexDirection='column'>
          <Typography variant='body1'>
            <ViewRichText text={comment.content} />
          </Typography>
          <Box display='flex' justifyContent='space-between' alignItems='center' mt={2}>
            <Typography variant='caption' color='textSecondary'>
              {timeAgo}
            </Typography>
            <Box>
              <Button
                variant='text'
                color='primary'
                onClick={handleEdit}
                style={{ textTransform: 'none', marginRight: '8px' }}
              >
                Edit
              </Button>
              <Button
                variant='text'
                color='error'
                onClick={() => onDelete(comment.id)}
                style={{ textTransform: 'none' }}
              >
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default FeedbackComment
