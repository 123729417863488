import { FeedbackStatus, FeedbackType, Platform } from 'common'
import { httpService } from '../../services/http.service'

const get = async ({
  page,
  pageSize,
  status,
}: {
  page: number
  pageSize: number
  status?: FeedbackStatus
}) => {
  const { feedback } = await httpService.post({
    url: '/get-feedback',
    data: {
      page,
      pageSize,
      status,
    },
  })

  return feedback
}

const create = async (data: {
  type: FeedbackType
  description: string
  userAgent?: string
  screenSize?: string
  pageURL?: string
  gitCommitHash?: string
  platform: Platform
  fileIds?: string[]
}) => {
  await httpService.post({
    url: '/create-feedback',
    data,
  })
}

const updateFeedbackStatus = async (data: { feedbackId: string; status: FeedbackStatus }) =>
  httpService.post({
    url: '/update-feedback-status',
    data,
  })

const addComment = async (data: { feedbackId: string; content: string }) =>
  httpService.post({
    url: '/create-comment',
    data,
  })

const editComment = async (data: { commentId: string; content: string }) =>
  httpService.post({
    url: '/edit-comment',
    data,
  })

const deleteComment = async (data: { commentId: string }) =>
  httpService.post({
    url: '/delete-comment',
    data,
  })

export const feedbackService = {
  get,
  create,
  updateFeedbackStatus,
  addComment,
  editComment,
  deleteComment,
}
