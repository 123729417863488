import React, { useState } from 'react'
import { Box, Checkbox, FormControlLabel, SxProps } from '@mui/material'
import ResponsiveNumberInput from '../custom-material-ui/responsive-number-input'

type MaxMinutesPerMealProps = {
  maxMinutes: number | null
  onChange: (value: number | null) => void
  minValue?: number
  maxValue?: number
  sx?: SxProps
}

const MaxMinutesPerMeal: React.FC<MaxMinutesPerMealProps> = ({
  maxMinutes,
  onChange,
  minValue = 5,
  maxValue = 120,
  sx,
}) => {
  const [isUnlimited, setIsUnlimited] = useState<boolean>(maxMinutes === null)
  const [value, setValue] = useState<number>(maxMinutes || minValue || 0)

  const handleValueChange = (newValue: number) => {
    onChange(newValue)
    setValue(newValue)
  }

  const handleUnlimitedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsUnlimited(event.target.checked)
    onChange(event.target.checked ? null : value)
  }

  return (
    <Box sx={sx}>
      <ResponsiveNumberInput
        value={value}
        onChange={handleValueChange}
        minValue={minValue}
        maxValue={maxValue}
        unitLabel='minutes'
        isDisabled={isUnlimited}
        leadingLabel='Enter the maximum'
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isUnlimited}
            onChange={handleUnlimitedChange}
            name='unlimitedTime'
            color='primary'
          />
        }
        label='No time limit'
      />
    </Box>
  )
}

export default MaxMinutesPerMeal
