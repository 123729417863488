import { useEffect, useState } from 'react'
import { User } from 'common'
import { userService } from './user.service'

export type FEUser = Pick<User, 'id' | 'name' | 'email'>

const useUser = () => {
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  const [user, setUser] = useState<FEUser>()

  useEffect(() => {
    const loadUser = async () => {
      setIsLoadingUser(true)
      try {
        const user = await userService.get()
        setUser(user)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingUser(false)
      }
    }

    loadUser()
  }, [])

  return {
    isLoadingUser,
    user,
    setUser,
  }
}

export default useUser
