import React, { createContext, useContext, ReactNode } from 'react'
import { useUserStatus } from './contexts/useUserStatus'

interface AuthState {
  isLoggedIn: boolean
  isAdmin: boolean
  refreshUserStatus: () => void
  isLoading: boolean
  isEmailVerified: boolean
}

export const AuthContext = createContext<AuthState>({
  isLoggedIn: false,
  isAdmin: false,
  refreshUserStatus: () => undefined,
  isLoading: true,
  isEmailVerified: false,
})

export const useAuth = () => {
  return useContext(AuthContext)
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const { isLoggedIn, isAdmin, refreshUserStatus, isLoading, isEmailVerified } = useUserStatus()

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, isAdmin, refreshUserStatus, isLoading, isEmailVerified }}
    >
      {children}
    </AuthContext.Provider>
  )
}
