import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  ButtonBase,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Modal,
  Fade,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Recipe } from 'common'
import CustomSnackbar from '../common/custom-snackbar'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { recipeService } from '../services/recipe.service'
import RecipeCard from '../common/small-recipe-card'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import { useSidebar } from '../contexts/useSideBar'
import { debounce } from 'lodash'

const PAGE_SIZE = 15 // Number of items per page

const DifficultyFilter = ({
  value,
  onChange,
}: {
  value: 'easy' | 'medium' | 'hard' | 'all'
  onChange: (i: 'easy' | 'medium' | 'hard' | 'all') => void
}) => {
  return (
    <FormControl variant='outlined'>
      <InputLabel id='difficulty-filter-label'>Difficulty</InputLabel>
      <Select
        labelId='difficulty-filter-label'
        onChange={(e) => onChange(e.target.value as 'easy' | 'medium' | 'hard' | 'all')}
        label='Difficulty'
        value={value}
        size='small'
      >
        <MenuItem value='all'>All</MenuItem>
        <MenuItem value='easy'>Easy</MenuItem>
        <MenuItem value='medium'>Medium</MenuItem>
        <MenuItem value='hard'>Hard</MenuItem>
      </Select>
    </FormControl>
  )
}

const ServesFilter = ({ value, onChange }: { value: number; onChange: (i: number) => void }) => {
  return (
    <FormControl variant='outlined'>
      <InputLabel id='serves-filter-label'>Serves</InputLabel>
      <Select
        labelId='serves-filter-label'
        onChange={(e) => {
          const selectedValue = Number(e.target.value)
          onChange(selectedValue)
        }}
        label='Serves'
        value={value}
        size='small'
      >
        <MenuItem value={-1}>All</MenuItem>
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
        <MenuItem value={4}>4</MenuItem>
        <MenuItem value={5}>5</MenuItem>
        <MenuItem value={6}>6</MenuItem>
        <MenuItem value={7}>7</MenuItem>
        <MenuItem value={8}>8</MenuItem>
        <MenuItem value={9}>9</MenuItem>
        <MenuItem value={10}>10</MenuItem>
      </Select>
    </FormControl>
  )
}

const SortBy = ({
  value,
  onChange,
}: {
  value:
    | 'caloriesHighToLow'
    | 'caloriesLowToHigh'
    | 'proteinHighToLow'
    | 'proteinLowToHigh'
    | 'none'
  onChange: (
    i: 'caloriesHighToLow' | 'caloriesLowToHigh' | 'proteinHighToLow' | 'proteinLowToHigh' | 'none',
  ) => void
}) => {
  return (
    <FormControl variant='outlined'>
      <InputLabel id='sort-by-label'>Sort By</InputLabel>
      <Select
        labelId='sort-by-label'
        onChange={(e) =>
          onChange(
            e.target.value as
              | 'caloriesHighToLow'
              | 'caloriesLowToHigh'
              | 'proteinHighToLow'
              | 'proteinLowToHigh'
              | 'none',
          )
        }
        label='Sort By'
        value={value}
        size='small'
      >
        <MenuItem value={'none'}>None</MenuItem>
        <MenuItem value='caloriesHighToLow'>Calories: High to Low</MenuItem>
        <MenuItem value='caloriesLowToHigh'>Calories: Low to High</MenuItem>
        <MenuItem value='proteinHighToLow'>Protein: High to Low</MenuItem>
        <MenuItem value='proteinLowToHigh'>Protein: Low to High</MenuItem>
      </Select>
    </FormControl>
  )
}

const MyRecipes = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([])
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [page, setPage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')

  const [filterDifficulty, setFilterDifficulty] = useState<'easy' | 'medium' | 'hard' | 'all'>(
    'all',
  )
  const [filterServes, setFilterServes] = useState<number>(-1)
  const [sortBy, setSortBy] = useState<
    'caloriesHighToLow' | 'caloriesLowToHigh' | 'proteinHighToLow' | 'proteinLowToHigh' | 'none'
  >('none')

  const [tempFilterDifficulty, setTempFilterDifficulty] = useState<
    'easy' | 'medium' | 'hard' | 'all'
  >('all')
  const [tempFilterServes, setTempFilterServes] = useState<number>(-1)
  const [tempSortBy, setTempSortBy] = useState<
    'caloriesHighToLow' | 'caloriesLowToHigh' | 'proteinHighToLow' | 'proteinLowToHigh' | 'none'
  >('none')

  const [isFilterModalOpen, setFilterModalOpen] = useState(false)

  const { isSidebarOpen } = useSidebar()

  const nav = useNavigate()

  useEffect(() => {
    const load = async () => {
      try {
        setLoading(true)
        const userRecipes = await recipeService.getUserRecipes({
          page,
          pageSize: PAGE_SIZE,
          difficulty: filterDifficulty === 'all' ? undefined : filterDifficulty,
          serves: filterServes === -1 ? undefined : filterServes,
          sortBy: sortBy === 'none' ? undefined : sortBy,
          searchTerm: debouncedSearchTerm === '' ? undefined : debouncedSearchTerm,
        })

        userRecipes && setRecipes(userRecipes)
        window.scrollTo(0, 0) // scroll to top when new page is loaded
      } catch (error) {
        console.log(error)
        setShowError(true)
      } finally {
        setLoading(false)
      }
    }

    load()
  }, [page, filterDifficulty, filterServes, sortBy, debouncedSearchTerm])

  useEffect(() => {
    setTempFilterDifficulty(filterDifficulty)
    setTempFilterServes(filterServes)
    setTempSortBy(sortBy)
  }, [isFilterModalOpen])

  const activeFilterCount = () => {
    let count = 0

    if (filterDifficulty !== 'all') count++
    if (filterServes !== -1) count++
    if (sortBy !== 'none') count++

    return count
  }

  const handleSearchTermChange = (value: string) => {
    setSearchTerm(value)
    debouncedSetSearchTerm(value)
  }

  // Using useCallback to memorize the debounced function
  const debouncedSetSearchTerm = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value)
    }, 500),
    [], // Dependency array is empty, so this function is only created once
  )

  return (
    <Box>
      <CustomSnackbar show={showError} setShow={setShowError} severity='error' />

      <Modal
        open={isFilterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        closeAfterTransition
        sx={{
          display: 'flex', // Using Flexbox
          alignItems: 'flex-start', // Align to top
          justifyContent: 'center', // Center horizontally
          paddingTop: '5vh', // 5% from the top of the viewport
          left: isSidebarOpen ? 240 : 0,
        }}
      >
        <Fade in={isFilterModalOpen}>
          <Box
            sx={{
              bgcolor: 'background.paper', // White background
              boxShadow: 24, // Drop shadow
              p: 4, // Padding
              borderRadius: '10px', // Rounded corners
              width: '80%', // Take 80% of screen width
              maxWidth: '500px', // Don't let it be wider than 500px
              display: 'flex',
              flexDirection: 'column',
              gap: 2, // space between items
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h6'>Filters</Typography>
              <Button
                size='small'
                onClick={() => {
                  setFilterDifficulty('all')
                  setFilterServes(-1)
                  setSortBy('none')
                  setFilterModalOpen(false)
                }}
                sx={{ textTransform: 'none' }}
              >
                Clear All
              </Button>
            </Box>

            <DifficultyFilter value={tempFilterDifficulty} onChange={setTempFilterDifficulty} />

            <ServesFilter value={tempFilterServes} onChange={setTempFilterServes} />

            <SortBy value={tempSortBy} onChange={setTempSortBy} />

            <Box sx={{ marginTop: '16px', display: 'flex', justifyContent: 'space-between' }}>
              <Button sx={{ textTransform: 'none' }} onClick={() => setFilterModalOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setFilterDifficulty(tempFilterDifficulty)
                  setFilterServes(tempFilterServes)
                  setSortBy(tempSortBy)
                  setFilterModalOpen(false)
                }}
                sx={{ textTransform: 'none' }}
              >
                Apply Filters
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          margin: '0 auto',
          width: '90%', // or whatever value makes sense for your layout
        }}
      >
        {/* Container for Search, Filters and Recipe Cards */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {/* Search & Filters */}
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center', // Centering the search and filters
              alignItems: 'center',
              mb: 2,
              mt: 2,
              ml: 1,
              mr: 1,
            }}
          >
            {/* Search box */}
            <ResponsiveTextField
              value={searchTerm}
              placeholder='Search recipes...'
              variant='outlined'
              sx={{ maxWidth: 350, width: '100%' }}
              onChange={(e) => {
                handleSearchTermChange(e.target.value)
              }}
              onClear={() => {
                setSearchTerm('')
                setDebouncedSearchTerm('')
              }}
            />

            {/* This will hide its children on large screens and up */}
            <Button sx={{ textTransform: 'none' }} onClick={() => setFilterModalOpen(true)}>
              Filters {activeFilterCount() > 0 ? `(${activeFilterCount()})` : ''}
            </Button>
          </Box>

          {/* Recipe Cards */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'center', // Centers the cards
              gap: 2,
            }}
          >
            {recipes.map((recipe) => (
              <ButtonBase
                key={recipe.id}
                sx={{
                  margin: 0,
                  padding: 0,
                }}
                onClick={() => {
                  nav(`/my-recipes/${recipe.id}`)
                }}
              >
                <RecipeCard recipe={recipe} />
              </ButtonBase>
            ))}
          </Box>
        </Box>

        {/* Pagination Controls */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 2,
            mb: 2,
          }}
        >
          <Button
            sx={{ textTransform: 'none' }}
            disabled={page === 1}
            onClick={() => setPage((prevPage) => prevPage - 1)}
          >
            <NavigateBefore /> Previous
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            disabled={recipes.length < PAGE_SIZE}
            onClick={() => setPage((prevPage) => prevPage + 1)}
          >
            Next <NavigateNext />
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default MyRecipes
