import React from 'react'
import { Card, CardContent, Typography, Chip, Box, Grid, CardMedia } from '@mui/material'
import { Recipe } from 'common'

type SmallRecipeCardProps = {
  recipe: Recipe
}

const difficultyColors = {
  easy: 'success',
  medium: 'warning',
  hard: 'error',
} as const

const SmallRecipeCard: React.FC<SmallRecipeCardProps> = ({ recipe }) => {
  return (
    <Card
      sx={{
        width: 300,
        borderRadius: 3,
        boxShadow: 2,
        backgroundColor: 'background.paper',
        overflow: 'hidden',
        '&:hover': {
          boxShadow: 10,
          cursor: 'pointer',
        },
      }}
    >
      {
        <CardMedia
          component='img'
          height='140'
          image={
            recipe.url || 'https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg'
          }
          alt={recipe.name}
        />
      }
      <CardContent>
        <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
          {recipe.name}
        </Typography>
        <Typography variant='body2' color='text.secondary' sx={{ marginTop: 1 }}>
          {recipe.description}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
          <Typography variant='body2' sx={{ marginRight: 1 }}>
            {recipe.prepTime} minutes
          </Typography>
          <Chip
            label={recipe.difficulty}
            sx={{
              backgroundColor: (theme) => theme.palette[difficultyColors[recipe.difficulty]].main,
              color: 'white',
              marginRight: 1,
            }}
          />
          <Typography variant='body2' sx={{ marginRight: 1 }}>
            Serves: {recipe.serves}
          </Typography>
        </Box>
        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          <Grid item xs={6}>
            <Typography variant='body2'>Calories: {recipe.calories}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2'>Carbs: {recipe.carbs}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2'>Protein: {recipe.protein}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2'>Fat: {recipe.fat}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2'>Fiber: {recipe.fiber}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='body2'>Sugar: {recipe.sugar}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default SmallRecipeCard
