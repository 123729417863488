import { MenuItem, SxProps } from '@mui/material'
import React from 'react'
import ResponsiveSelect from '../custom-material-ui/responsive-select'

type MealTypeProps = {
  sx?: SxProps
  mealType: string
  onChange: (value: string) => void
}

const MealType: React.FC<MealTypeProps> = ({ sx, mealType, onChange }) => {
  return (
    <ResponsiveSelect
      sx={sx}
      label='What type of meal do you want to make?'
      value={mealType}
      onChange={(value) => onChange(value)}
    >
      <MenuItem value='breakfast'>Breakfast</MenuItem>
      <MenuItem value='lunch'>Lunch</MenuItem>
      <MenuItem value='dinner'>Dinner</MenuItem>
      <MenuItem value='snack'>A snack</MenuItem>
      <MenuItem value='dessert'>Dessert</MenuItem>
    </ResponsiveSelect>
  )
}

export default MealType
