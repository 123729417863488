import React, { useEffect, useState } from 'react'
import {
  Modal,
  Button,
  Box,
  Typography,
  Fade,
  CircularProgress,
  Tabs,
  Tab,
  Paper,
} from '@mui/material'
import { UserPreference } from 'common'
import Allergies from '../../profile/allergy-intolerance'
import SkillLevelSelect from '../../profile/skill-level'
import DietaryRequirements from '../../profile/dietary-preferences'
import KitchenTools from '../../profile/kitchen-tools'
import MaxMinutesPerMeal from './max-minutes-per-meal'
import { useSidebar } from '../../contexts/useSideBar'

function getDifferences<T>(oldArray: T[], newArray: T[]) {
  const added = newArray.filter((x) => !oldArray.includes(x))
  const removed = oldArray.filter((x) => !newArray.includes(x))
  return { added, removed }
}

function DietaryRequirementsWithDifferences({
  userPreference,
  defaultUserPreference,
  onChange,
}: {
  userPreference: UserPreference
  defaultUserPreference: UserPreference
  onChange: (userPreference: UserPreference) => void
}) {
  const { added, removed } = getDifferences(
    defaultUserPreference.dietaryRequirements,
    userPreference.dietaryRequirements,
  )

  return (
    <Box>
      <DietaryRequirements
        sx={{ width: '100%', mt: 2 }}
        dietaryRequirements={userPreference.dietaryRequirements}
        onChange={(val) => onChange({ ...userPreference, dietaryRequirements: val })}
      />

      {added.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve added: {added.join(', ')}
          </Typography>
        </Box>
      )}
      {removed.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve removed: {removed.join(', ')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function AllergiesWithDifferences({
  userPreference,
  defaultUserPreference,
  onChange,
}: {
  userPreference: UserPreference
  defaultUserPreference: UserPreference
  onChange: (userPreference: UserPreference) => void
}) {
  const { added, removed } = getDifferences(
    defaultUserPreference.allergies,
    userPreference.allergies,
  )

  return (
    <Box>
      <Allergies
        sx={{ width: '100%', mt: 2 }}
        allergies={userPreference.allergies}
        onChange={(val) => onChange({ ...userPreference, allergies: val })}
      />

      {added.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve added: {added.join(', ')}
          </Typography>
        </Box>
      )}
      {removed.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve removed: {removed.join(', ')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function KitchenToolsWithDifferences({
  userPreference,
  defaultUserPreference,
  onChange,
}: {
  userPreference: UserPreference
  defaultUserPreference: UserPreference
  onChange: (userPreference: UserPreference) => void
}) {
  const { added, removed } = getDifferences(
    defaultUserPreference.kitchenTools,
    userPreference.kitchenTools,
  )

  return (
    <Box>
      <KitchenTools
        sx={{ width: '100%', mt: 2 }}
        kitchenTools={userPreference.kitchenTools}
        onChange={(val) => onChange({ ...userPreference, kitchenTools: val })}
      />

      {added.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve added: {added.join(', ')}
          </Typography>
        </Box>
      )}
      {removed.length > 0 && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            You&apos;ve removed: {removed.join(', ')}
          </Typography>
        </Box>
      )}
    </Box>
  )
}
function MaxMinutesPerMealWithDifferences({
  userPreference,
  defaultUserPreference,
  onChange,
}: {
  userPreference: UserPreference
  defaultUserPreference: UserPreference
  onChange: (userPreference: UserPreference) => void
}) {
  function getMinutesString(minutes: number | null) {
    if (!minutes) {
      return 'no time limit'
    } else if (minutes === 1) {
      return '1 minute'
    } else {
      return `${minutes} minutes`
    }
  }

  return (
    <Box>
      <MaxMinutesPerMeal
        sx={{ width: '100%', mt: 2 }}
        maxMinutes={userPreference.maxMinutesPerMeal}
        onChange={(val) => onChange({ ...userPreference, maxMinutesPerMeal: val })}
      />

      {userPreference.maxMinutesPerMeal !== defaultUserPreference.maxMinutesPerMeal && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            Changed from {getMinutesString(defaultUserPreference.maxMinutesPerMeal)} to{' '}
            {getMinutesString(userPreference.maxMinutesPerMeal)}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

function SkillLevelWithDifferences({
  userPreference,
  defaultUserPreference,
  onChange,
}: {
  userPreference: UserPreference
  defaultUserPreference: UserPreference
  onChange: (userPreference: UserPreference) => void
}) {
  return (
    <Box sx={{ width: '100%' }}>
      <SkillLevelSelect
        sx={{ mt: 2 }}
        skillLevel={userPreference.skillLevel}
        onChange={(val) => onChange({ ...userPreference, skillLevel: val })}
      />

      {userPreference.skillLevel !== defaultUserPreference.skillLevel && (
        <Box>
          <Typography fontSize={14} variant='caption'>
            Changed from {defaultUserPreference.skillLevel} to {userPreference.skillLevel}
          </Typography>
        </Box>
      )}
    </Box>
  )
}

type OverrideUserPreferenceProps = {
  userPreference?: UserPreference
  onChange: (userPreference: UserPreference) => void
}

const OverrideUserPreference: React.FC<OverrideUserPreferenceProps> = ({
  userPreference,
  onChange,
}) => {
  const [defaultUserPreference, setDefaultUserPreference] = useState<UserPreference | undefined>(
    userPreference,
  )

  const [isOpen, setIsOpen] = useState(false)
  const [tabValue, setTabValue] = useState(0) // For tabs
  const { isSidebarOpen } = useSidebar()

  useEffect(() => {
    if (!defaultUserPreference && userPreference) {
      setDefaultUserPreference(userPreference)
    }
  }, [userPreference])

  const getArrayDifferencesCount = (newArray: any[], originalArray: any[]): number => {
    const added = newArray.filter((item) => !originalArray.includes(item)).length
    const removed = originalArray.filter((item) => !newArray.includes(item)).length

    return added + removed
  }

  const calculateOverrides = () => {
    if (!userPreference || !defaultUserPreference) {
      return 0
    }

    let count = 0

    count += getArrayDifferencesCount(
      userPreference.dietaryRequirements,
      defaultUserPreference.dietaryRequirements,
    )

    count += getArrayDifferencesCount(userPreference.allergies, defaultUserPreference.allergies)

    count += getArrayDifferencesCount(
      userPreference.kitchenTools,
      defaultUserPreference.kitchenTools,
    )

    if (userPreference.maxMinutesPerMeal !== defaultUserPreference.maxMinutesPerMeal) {
      count++
    }

    if (userPreference.skillLevel !== defaultUserPreference.skillLevel) {
      count++
    }

    return count
  }

  const overrideCount = calculateOverrides()
  const overrideText = overrideCount === 1 ? 'preference' : 'preferences'

  const formatTabLabel = (count: number, label: string) => {
    if (count === 0) return label
    return `${label} (${count})`
  }

  return (
    <>
      <Button
        sx={{ alignSelf: 'flex-start', textTransform: 'none' }}
        onClick={() => setIsOpen(true)}
        disabled={!userPreference} // Disable the button if preferences are loading
      >
        {userPreference
          ? overrideCount > 0
            ? `You have overridden ${overrideCount} ${overrideText}`
            : 'Override my preferences'
          : 'Loading your preferences...'}
      </Button>

      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          paddingTop: '3vh',
          left: isSidebarOpen ? 240 : 0,
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: '10px',
              width: '90%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              left: isSidebarOpen ? 240 : 0,
              overflowY: 'auto', // Enable vertical scrolling
              maxHeight: '80vh', // Limit height to 80% of the view height
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant='h6'>Override Preferences</Typography>
              <Button
                size='small'
                onClick={() => {
                  // Logic to reset all preferences
                  defaultUserPreference && onChange({ ...defaultUserPreference })
                  setIsOpen(false)
                }}
                sx={{ textTransform: 'none' }}
              >
                Reset
              </Button>
            </Box>

            {userPreference && defaultUserPreference ? (
              <>
                <Paper sx={{ mt: 2, flexGrow: 1 }}>
                  <Tabs
                    value={tabValue}
                    onChange={(event, newValue) => setTabValue(newValue)}
                    variant='fullWidth'
                  >
                    <Tab
                      sx={{ textTransform: 'none' }}
                      label={formatTabLabel(
                        getArrayDifferencesCount(
                          userPreference?.dietaryRequirements || [],
                          defaultUserPreference?.dietaryRequirements || [],
                        ) +
                          getArrayDifferencesCount(
                            userPreference?.allergies || [],
                            defaultUserPreference?.allergies || [],
                          ),
                        'Diet & Allergies',
                      )}
                    />
                    <Tab
                      sx={{ textTransform: 'none' }}
                      label={formatTabLabel(
                        getArrayDifferencesCount(
                          userPreference?.kitchenTools || [],
                          defaultUserPreference?.kitchenTools || [],
                        ) +
                          (userPreference?.maxMinutesPerMeal !==
                          defaultUserPreference?.maxMinutesPerMeal
                            ? 1
                            : 0),
                        'Kitchen & Time',
                      )}
                    />
                    <Tab
                      sx={{ textTransform: 'none' }}
                      label={formatTabLabel(
                        userPreference?.skillLevel !== defaultUserPreference?.skillLevel ? 1 : 0,
                        'Skill Level',
                      )}
                    />
                  </Tabs>
                </Paper>
                {/* Diet & Allergies Tab */}
                {tabValue === 0 && (
                  <>
                    <DietaryRequirementsWithDifferences
                      userPreference={userPreference}
                      defaultUserPreference={defaultUserPreference}
                      onChange={(val) =>
                        onChange({
                          ...userPreference,
                          dietaryRequirements: val.dietaryRequirements,
                        })
                      }
                    />

                    <AllergiesWithDifferences
                      userPreference={userPreference}
                      defaultUserPreference={defaultUserPreference}
                      onChange={(val) => onChange({ ...userPreference, allergies: val.allergies })}
                    />
                  </>
                )}

                {/* Kitchen & Time Tab */}
                {tabValue === 1 && (
                  <>
                    <KitchenToolsWithDifferences
                      userPreference={userPreference}
                      defaultUserPreference={defaultUserPreference}
                      onChange={(val) =>
                        onChange({ ...userPreference, kitchenTools: val.kitchenTools })
                      }
                    />
                    <MaxMinutesPerMealWithDifferences
                      userPreference={userPreference}
                      defaultUserPreference={defaultUserPreference}
                      onChange={(val) =>
                        onChange({ ...userPreference, maxMinutesPerMeal: val.maxMinutesPerMeal })
                      }
                    />
                  </>
                )}

                {/* Skill Level Tab */}
                {tabValue === 2 && (
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <SkillLevelWithDifferences
                      userPreference={userPreference}
                      defaultUserPreference={defaultUserPreference}
                      onChange={(val) =>
                        onChange({ ...userPreference, skillLevel: val.skillLevel })
                      }
                    />
                  </Box>
                )}
              </>
            ) : (
              <CircularProgress size={20} />
            )}
            <Box
              sx={{
                marginTop: '16px',
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
              }}
            >
              <Button
                onClick={() => {
                  // Logic to apply changes
                  setIsOpen(false)
                }}
                sx={{ textTransform: 'none' }}
              >
                Done
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  )
}

export default OverrideUserPreference
