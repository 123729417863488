import { httpService } from '../services/http.service'

const login = async ({
  email,
  password,
  refreshUserStatus,
}: {
  email: string
  password: string
  refreshUserStatus: () => void
}) => {
  await httpService.post({
    url: '/login',
    data: { email, password },
  })

  localStorage.setItem('userStatusChange', Date.now().toString())
  refreshUserStatus()
}

const signUp = async ({
  email,
  password,
  refreshUserStatus,
}: {
  email: string
  password: string
  refreshUserStatus: () => void
}) => {
  await httpService.post({
    url: '/sign-up',
    data: { email, password },
  })

  localStorage.setItem('userStatusChange', Date.now().toString())
  refreshUserStatus()
}

const logout = async ({ refreshUserStatus }: { refreshUserStatus: () => void }) => {
  await httpService.post({ url: '/logout' })

  localStorage.setItem('userStatusChange', Date.now().toString())
  refreshUserStatus()
}

const sendEmailVerification = async () => {
  await httpService.post({ url: '/send-email-verification-code' })
}

const verifyEmail = async ({
  token,
  refreshUserStatus,
}: {
  token: string
  refreshUserStatus?: () => void
}) => {
  await httpService.post({ url: '/verify-email', data: { token } })

  localStorage.setItem('userStatusChange', Date.now().toString())
  refreshUserStatus && refreshUserStatus()
}

const sendPasswordResetCode = async ({ email }: { email: string }) => {
  await httpService.post({ url: '/send-password-reset-code', data: { email } })
}

const resetPassword = async ({
  email,
  token,
  newPassword,
  confirmNewPassword,
}: {
  email: string
  token: string
  newPassword: string
  confirmNewPassword: string
}) => {
  await httpService.post({
    url: '/reset-password',
    data: { email, token, newPassword, confirmNewPassword },
  })
}

const changePassword = async ({
  currentPassword,
  newPassword,
  confirmNewPassword,
}: {
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
}) => {
  await httpService.post({
    url: '/change-user-password',
    data: { currentPassword, newPassword, confirmNewPassword },
  })
}

const getUserStatus = async () => {
  return httpService.post({ url: '/get-user-status' })
}

export const authService = {
  login,
  signUp,
  logout,
  changePassword,
  getUserStatus,
  sendEmailVerification,
  verifyEmail,
  sendPasswordResetCode,
  resetPassword,
}
