import React from 'react'

type ViewRichTextProps = {
  text: string
}

const ViewRichText: React.FC<ViewRichTextProps> = ({ text }) => {
  const newText = text.split('\n').map((str, index, array) => (
    <React.Fragment key={index}>
      {str}
      {index === array.length - 1 ? null : <br />}
    </React.Fragment>
  ))

  return <>{newText}</>
}

export default ViewRichText
