import React from 'react'
import { SxProps } from '@mui/system'
import { MenuItem } from '@mui/material'
import { Theme } from '@mui/material'
import ResponsiveSelect from '../custom-material-ui/responsive-select'

type NumberOfIngredientsProps = {
  sx?: SxProps<Theme>
  numberOfIngredients: number
  onChange: (value: number) => void
}

const NumberOfIngredients: React.FC<NumberOfIngredientsProps> = ({
  sx,
  numberOfIngredients,
  onChange,
}) => {
  return (
    <ResponsiveSelect
      sx={sx}
      variant='outlined'
      value={numberOfIngredients}
      onChange={(e) => onChange(e)}
      label='Number of ingredients'
    >
      <MenuItem value={3}>3</MenuItem>
      <MenuItem value={4}>4</MenuItem>
      <MenuItem value={5}>5</MenuItem>
    </ResponsiveSelect>
  )
}

export default NumberOfIngredients
