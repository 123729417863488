import React from 'react'
import { MenuItem, SxProps } from '@mui/material'
import ResponsiveSelect from '../custom-material-ui/responsive-select'

type StoragePreferenceProps = {
  sx?: SxProps
  storagePreferences: string[]
  onChange: (value: string[]) => void
}

const StoragePreference: React.FC<StoragePreferenceProps> = ({
  sx,
  storagePreferences,
  onChange,
}) => {
  return (
    <ResponsiveSelect
      sx={sx}
      selectProps={{ multiple: true }}
      value={storagePreferences}
      onChange={(e) => onChange(e)}
      label='Where do you want to store your meals?'
    >
      <MenuItem value='fridge'>Fridge</MenuItem>
      <MenuItem value='freezer'>Freezer</MenuItem>
      <MenuItem value='pantry'>Pantry</MenuItem>
    </ResponsiveSelect>
  )
}

export default StoragePreference
