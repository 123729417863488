import { Button } from '@mui/material'
import { Box } from '@mui/system'
import { FeedbackWithCommentsAndFiles } from 'common'
import React from 'react'

type FeedbacksActionsProps = {
  feedback: FeedbackWithCommentsAndFiles
  onToggleComments: () => void
  onToggleFiles: () => void
}

const FeedbackActions: React.FC<FeedbacksActionsProps> = ({
  feedback,
  onToggleComments,
  onToggleFiles,
}) => {
  return (
    <Box>
      <Button size='small' onClick={onToggleFiles}>
        Files ({feedback.files.length})
      </Button>
      <Button size='small' onClick={onToggleComments}>
        Comments ({feedback.comments.length})
      </Button>
    </Box>
  )
}

export default FeedbackActions
