import { SxProps } from '@mui/material'
import React from 'react'
import ResponsiveAutoComplete from '../custom-material-ui/responsive-autocomplete'

const cuisineTypes = [
  'All',
  'American',
  'Argentinian',
  'Australian',
  'Brazilian',
  'British',
  'Cajun/Creole',
  'Caribbean',
  'Chinese',
  'Cuban',
  'Egyptian',
  'Ethiopian',
  'Filipino',
  'French',
  'German',
  'Greek',
  'Hungarian',
  'Indian',
  'Indonesian',
  'Irish',
  'Israeli',
  'Italian',
  'Jamaican',
  'Japanese',
  'Korean',
  'Lebanese',
  'Malaysian',
  'Mediterranean',
  'Mexican',
  'Moroccan',
  'Nigerian',
  'Pakistani',
  'Persian',
  'Peruvian',
  'Polish',
  'Portuguese',
  'Russian',
  'Southern/Soul Food',
  'Spanish',
  'Swedish',
  'Thai',
  'Turkish',
  'Vietnamese',
] as const

type BaseCuisineTypeProps = {
  sx?: SxProps
  multiple?: boolean
  label?: string
}

type SingleCuisineTypeProps = BaseCuisineTypeProps & {
  multiple?: false
  maxSelections?: never
  cuisineType: string
  onChange: (value: string) => void
}

type MultipleCuisineTypeProps = BaseCuisineTypeProps & {
  multiple?: true
  maxSelections: number
  cuisineType: string[]
  onChange: (value: string[]) => void
}

type CuisineTypeProps = SingleCuisineTypeProps | MultipleCuisineTypeProps

const CuisineType: React.FC<CuisineTypeProps> = ({
  sx,
  multiple = false,
  maxSelections,
  label = 'Cuisine type',
  cuisineType,
  onChange,
}) => {
  return (
    <ResponsiveAutoComplete
      sx={sx}
      multiple={multiple}
      value={cuisineType}
      onChange={(event, newValue) => newValue && onChange(newValue)}
      options={multiple ? cuisineTypes.filter((c) => c !== 'All') : cuisineTypes}
      label={label}
      getOptionDisabled={(option) =>
        multiple &&
        !!maxSelections &&
        Array.isArray(cuisineType) &&
        cuisineType.length >= maxSelections &&
        !cuisineType.includes(option)
      }
    />
  )
}

export default CuisineType
