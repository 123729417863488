import { FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import React from 'react'

interface SinglePickBoxProps {
  sx?: SxProps
  options: { value: string; label: string }[]
  selectedOption: string
  label: string
  onSelect: (value: string) => void
}

const SinglePickBox: React.FC<SinglePickBoxProps> = ({
  sx,
  options,
  selectedOption,
  label,
  onSelect,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect((event.target as HTMLInputElement).value)
  }

  return (
    <Paper elevation={3} sx={{ p: 2, ...sx }}>
      <Typography variant='body1' gutterBottom sx={{ mb: 1 }}>
        {label}
      </Typography>
      <RadioGroup
        aria-label='single-option'
        value={selectedOption}
        onChange={handleChange}
        sx={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </Paper>
  )
}

export default SinglePickBox
