import { RecipeMode, RouteSSEvents, UserPreference } from 'common'
import { httpService } from './http.service'

const setup = async ({
  recipeMode,
  userPreference,
}: {
  recipeMode: RecipeMode
  userPreference: UserPreference
}) => {
  const { setupId } = await httpService.post({
    url: '/setup-create-recipe',
    data: {
      recipeMode,
      userPreference,
    },
  })

  return setupId
}

const create = ({
  sseSessionId,
  onEvent,
  onApiErrorMessage,
}: {
  sseSessionId: string
  onEvent: (data: RouteSSEvents['/create-recipe']) => void
  onApiErrorMessage: (error: unknown) => void
}) => {
  const sse = httpService.postSSE(
    {
      url: '/create-recipe',
      data: {
        sseSessionId,
      },
    },
    onEvent,
    onApiErrorMessage,
  )

  return sse
}

const getUserRecipes = async ({
  page,
  pageSize,
  difficulty,
  serves,
  sortBy,
  searchTerm,
}: {
  page: number
  pageSize: number
  difficulty?: 'easy' | 'medium' | 'hard'
  serves?: number
  sortBy?: 'caloriesHighToLow' | 'caloriesLowToHigh' | 'proteinHighToLow' | 'proteinLowToHigh'
  searchTerm?: string
}) => {
  try {
    const { recipes } = await httpService.post({
      url: '/get-user-recipes',
      data: { page, pageSize, difficulty, serves, sortBy, searchTerm },
    })
    return recipes
  } catch (e) {
    console.error(e)
  }
}

const getRecipe = async ({ id }: { id: string }) => {
  try {
    const { recipe } = await httpService.post({
      url: '/get-recipe',
      data: {
        recipeId: id,
      },
    })
    return recipe
  } catch (e) {
    console.error(e)
  }
}

const deleteRecipe = async ({ id }: { id: string }) => {
  try {
    await httpService.post({
      url: '/delete-recipe',
      data: {
        recipeId: id,
      },
    })
  } catch (e) {
    console.error(e)
  }
}

export const recipeService = {
  setup,
  create,
  getUserRecipes,
  getRecipe,
  deleteRecipe,
}
