import { useState, useEffect, useCallback } from 'react'
import { authService } from '../login/auth.service'

export const useUserStatus = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isEmailVerified, setIsEmailVerified] = useState(false)

  const fetchUserStatus = useCallback(async () => {
    setIsLoading(true)
    try {
      const { isLoggedIn, isAdmin, isEmailVerified } = await authService.getUserStatus()

      setTimeout(() => {
        setIsLoggedIn(isLoggedIn)
        setIsAdmin(isAdmin)
        setIsEmailVerified(isEmailVerified)
        setIsLoading(false)
      }, 1000)
    } catch (error) {
      setIsLoggedIn(false)
      setIsAdmin(false)
      setIsEmailVerified(false)
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchUserStatus()
  }, [fetchUserStatus])

  const refreshUserStatus = () => {
    fetchUserStatus()
  }

  return { isLoggedIn, isAdmin, refreshUserStatus, isLoading, isEmailVerified }
}
