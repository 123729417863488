import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Toolbar,
  Button,
  useMediaQuery,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from '@mui/material'
import { recipeService } from '../services/recipe.service'
import LargeRecipeCard from '../common/large-recipe-card'
import { Delete, NavigateBefore, ShoppingCart } from '@mui/icons-material'
import { useSidebar } from '../contexts/useSideBar'
import { shoppingListService } from '../my-shopping-list/shopping-list.service'
import { Recipe, ShoppingList } from 'common'
import LargeShoppingListCard from '../common/large-shopping-list-card'
import FreeTierBanner from '../common/free-tier-banner'
import { useUserSubscription } from '../contexts/useUserSubscription'
import { Container } from '@mui/system'
import RecipeNotFound from './recipe-not-found'

const ViewRecipe = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    return <div>Error: Recipe id is missing</div>
  }

  const [isLoading, setIsLoading] = useState(true)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [recipe, setRecipe] = useState<Recipe | null>(null)
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCreatingShoppingList, setIsCreatingShoppingList] = useState(false)
  const [shop, setShop] = useState(false)
  const [shoppingList, setShoppingList] = useState<ShoppingList>()
  const { isSidebarOpen } = useSidebar()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const { atShoppingListLimit, updateLimits } = useUserSubscription()

  const nav = useNavigate()

  const handleClickOpen = () => {
    setOpenDeleteDialog(true)
  }

  const handleClose = () => {
    setOpenDeleteDialog(false)
  }

  const handleDelete = async () => {
    if (!recipe) {
      return
    }
    try {
      setIsDeleting(true)
      await recipeService.deleteRecipe({ id: recipe.id })
    } catch (e) {
      console.error(e)
    } finally {
      setIsDeleting(false)
      handleClose()
      nav('/my-recipes')
    }
  }

  const handleBackButtonClick = () => {
    if (shop) {
      return setShop(false)
    }

    nav('/my-recipes')
  }

  const handleShopButtonClick = async () => {
    if (!recipe) {
      return
    }

    if (shoppingList) {
      return setShop(!shop)
    }

    setIsCreatingShoppingList(true)

    const newShoppingList = await shoppingListService.create({
      recipeIds: [recipe.id],
    })

    setIsCreatingShoppingList(false)

    await updateLimits()
    setShop(!shop)
    setShoppingList(newShoppingList)
  }

  useEffect(() => {
    setIsLoading(true)

    const getRecipe = async () => {
      try {
        const recipe = await recipeService.getRecipe({ id })

        if (!recipe) {
          return
        }

        setRecipe(recipe)

        const userHasShoppingListInstance =
          await shoppingListService.checkIfUserHasShoppingListInstance({
            recipeId: recipe.id,
          })

        if (userHasShoppingListInstance) {
          const shoppingList = await shoppingListService.getShoppingListForRecipe({
            recipeId: recipe.id,
          })

          setShoppingList(shoppingList)
        }
      } finally {
        setIsLoading(false)
      }
    }

    getRecipe()
  }, [])

  return recipe ? (
    <Container style={{ position: 'relative' }}>
      <FreeTierBanner type='shoppingList' />
      <Dialog
        sx={{
          position: 'fixed',
          left: isSidebarOpen ? 240 : 0,
        }}
        open={isCreatingShoppingList}
        aria-labelledby='creating-shopping-list-dialog-title'
        aria-describedby='creating-shopping-list-dialog-description'
      >
        <DialogTitle id='creating-shopping-list-dialog-title'>Creating Shopping List</DialogTitle>
        <DialogContent>
          <DialogContentText id='creating-shopping-list-dialog-description'>
            {`We're creating your shopping list. This is a one-time action, and the list will be
            available for future use. Please wait while we prepare your list.`}
          </DialogContentText>
          <Box display='flex' justifyContent='center' marginTop={2}>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
        <Toolbar
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: '1024px',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Button
              sx={{ textTransform: 'none', mr: 1 }}
              variant='contained'
              startIcon={<NavigateBefore />}
              onClick={handleBackButtonClick}
            >
              {!isSmallScreen && 'Back'}
            </Button>

            {!shop && (
              <Button
                sx={{ textTransform: 'none', mr: 1 }}
                variant='contained'
                startIcon={<ShoppingCart />}
                onClick={handleShopButtonClick}
                disabled={atShoppingListLimit && !shoppingList}
              >
                {!isSmallScreen && 'Shop'}
              </Button>
            )}
          </Box>

          <Box>
            <Button
              sx={{ textTransform: 'none' }}
              color='error'
              variant='contained'
              startIcon={<Delete />}
              onClick={handleClickOpen}
            >
              {!isSmallScreen && 'Delete'}
            </Button>
          </Box>
        </Toolbar>

        {shop && shoppingList && (
          <LargeShoppingListCard recipe={recipe} shoppingList={shoppingList} />
        )}
        {recipe && !shop && <LargeRecipeCard recipe={recipe} />}
      </Box>
      <Dialog
        sx={{
          position: 'fixed',
          left: isSidebarOpen ? 240 : 0,
        }}
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Delete Recipe'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete this recipe? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: 'none' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button sx={{ textTransform: 'none' }} onClick={handleDelete} color='error' autoFocus>
            {isDeleting ? `Deleting` : `Delete recipe`}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  ) : !isLoading ? (
    <RecipeNotFound />
  ) : null
}

export default ViewRecipe
