import { Mic, MicOff } from '@mui/icons-material'
import { Button, CircularProgress, SxProps } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { httpService } from '../services/http.service'

type AudioRecorderProps = {
  sx?: SxProps
  setText: (value: string) => void
}

const AudioRecorder: React.FC<AudioRecorderProps> = ({ setText, sx }) => {
  const [recording, setRecording] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [isMediaRecorderSupported, setIsMediaRecorderSupported] = useState(false)

  useEffect(() => {
    if (window.MediaRecorder) {
      setIsMediaRecorderSupported(true)
    }
  }, [])

  const handleRecording = () => {
    if (!isMediaRecorderSupported) return

    if (!recording) {
      startRecording()
    } else {
      stopRecording()
    }
  }

  const startRecording = () => {
    const constraints = { audio: true }
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const newMediaRecorder = new MediaRecorder(stream)
        newMediaRecorder.start()
        setMediaRecorder(newMediaRecorder)
        setRecording(true)
      })
      .catch((error) => {
        console.error('Error: ', error)
      })
  }

  const stopRecording = async () => {
    if (mediaRecorder) {
      mediaRecorder.ondataavailable = async (e) => {
        setRecording(false)
        setProcessing(true)

        // Create a FormData instance
        const formData = new FormData()

        if (!e.data?.size) return

        formData.append('audio', e.data)

        try {
          // Make a POST request to your Express server
          const res = await httpService.post({
            url: '/voice-to-text',
            data: formData,
            config: { headers: { 'Content-Type': 'multipart/form-data' } },
          })
          setText(res.text)
        } catch (error) {
          console.error('Error: ', error)
        }

        setProcessing(false)
      }
      mediaRecorder.stop()
      mediaRecorder.stream.getTracks().forEach((track) => track.stop()) // Stop the media stream
      setMediaRecorder(null)
    }
  }

  return (
    <Button
      variant='contained'
      color={recording ? 'secondary' : 'primary'}
      style={{
        margin: 1,
        textTransform: 'none',
        position: 'relative',
      }}
      startIcon={recording ? <MicOff /> : <Mic />}
      onClick={handleRecording}
      disabled={!isMediaRecorderSupported || processing}
      sx={sx}
    >
      {recording ? 'Stop' : processing ? 'Processing' : 'Start'}
      {processing && <CircularProgress size={24} />}
    </Button>
  )
}

export default AudioRecorder
