import React from 'react'
import { useTheme, useMediaQuery, Tooltip, Typography, Button } from '@mui/material'
import FeedbackIcon from '@mui/icons-material/Feedback'

interface FeedbackButtonProps {
  onClick: () => void
}

const FeedbackButton: React.FC<FeedbackButtonProps> = ({ onClick }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const baseStyling = {
    borderRadius: '6px',
    position: 'fixed',
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    transformOrigin: 'center',
    zIndex: 999,
    transition: '0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
    '& > span': {
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(1),
    },
  }

  if (isMobile) {
    return (
      <Tooltip title='Feedback'>
        <Button
          variant='contained'
          size='small'
          onClick={onClick}
          sx={{
            ...baseStyling,
            minWidth: 'auto',
            minHeight: 'auto',
            height: 30,
            width: 30,
            padding: 1,
            '& .MuiButton-startIcon, & .MuiButton-endIcon': {
              margin: 0,
            },
          }}
        >
          <FeedbackIcon />
        </Button>
      </Tooltip>
    )
  } else {
    return (
      <Button variant='contained' size='small' onClick={onClick} sx={baseStyling}>
        <Typography variant='button' component='span'>
          Feedback
        </Typography>
      </Button>
    )
  }
}

export default FeedbackButton
