import React from 'react'
import { AppBar, Box, IconButton, Typography } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

import { DarkMode, LightMode } from '@mui/icons-material'
import { keyLabels } from '../app-menu-item'
import { useSidebar } from '../contexts/useSideBar'
import { useLocation } from 'react-router-dom'

import TopBarMenu from './top-bar-menu'

type TopBarProps = {
  isLightMode: boolean
  handleDarkLightModeChange: () => void
}

const TopBar: React.FC<TopBarProps> = ({ isLightMode, handleDarkLightModeChange }) => {
  const { isSidebarOpen, setIsSidebarOpen } = useSidebar()

  const location = useLocation() // Get the current URL
  const menuItemLabel = location.pathname.split('/')[1] // Extract the label from the URL

  return (
    <Box>
      <AppBar position='static' sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            sx={{ mr: 2, ml: 1 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={{ flexGrow: 1 }}>
            {`${keyLabels[menuItemLabel] || ''}`}
          </Typography>
          <IconButton color='inherit' onClick={handleDarkLightModeChange}>
            {isLightMode ? <DarkMode /> : <LightMode />}
          </IconButton>
          <TopBarMenu />
        </Box>
      </AppBar>
    </Box>
  )
}

export default TopBar
