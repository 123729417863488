import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import ResponsiveTextField from '../../common/custom-material-ui/responsive-text-field'

type AddCommentProps = {
  feedbackId: string
  onAddComment: (feedbackId: string, commentText: string) => void
}

const AddComment: React.FC<AddCommentProps> = ({ feedbackId, onAddComment }) => {
  const [comment, setComment] = useState('')

  const handleAddComment = () => {
    onAddComment(feedbackId, comment)
    setComment('') // Clear the input after adding
  }

  return (
    <Box mt={2}>
      <ResponsiveTextField
        fullWidth
        multiline
        rows={3}
        variant='outlined'
        label='Add a comment'
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        margin='normal'
      />
      <Box display='flex' justifyContent='flex-end' mt={1}>
        <Button
          variant='text'
          color='primary'
          size='small'
          onClick={handleAddComment}
          style={{ textTransform: 'none' }}
          disabled={!comment.trim()} // Disable if comment is empty or just whitespace
        >
          Add Comment
        </Button>
      </Box>
    </Box>
  )
}

export default AddComment
