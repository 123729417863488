import React, { useContext, useEffect, useState } from 'react'
import './scrollbar.css'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { AuthContext, AuthProvider } from './auth-context'
import { SidebarProvider } from './contexts/useSideBar'
import { UserSubscriptionProvider } from './contexts/useUserSubscription'
import RollbarErrorBoundary from './error-boundary'
import { CircularProgress, CssBaseline, createTheme, ThemeProvider } from '@mui/material'

function AppWrapper() {
  const { isLoggedIn, refreshUserStatus, isLoading } = useContext(AuthContext)
  const [isLightMode, setIsLightMode] = useState(!!localStorage.getItem('isLightMode') || false)

  const handleDarkLightModeChange = () => {
    const isLightMode = localStorage.getItem('isLightMode')

    if (isLightMode) {
      localStorage.removeItem('isLightMode')
      setIsLightMode(false)
    } else {
      localStorage.setItem('isLightMode', 'true')
      setIsLightMode(true)
    }
  }

  useEffect(() => {
    function handleStorageChange(event: StorageEvent) {
      if (event.key === 'userStatusChange') {
        refreshUserStatus()
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      // Clean up the listener when the component is unmounted
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  // blue: rgb(16,12,71) || 100C47
  // white: rgb(252,252,247) || FCFCF7
  // TO-DO: Figure out how to make light mode look good
  // dark mode is okay for now
  const theme = createTheme({
    palette: {
      mode: isLightMode ? 'light' : 'dark',
      ...(!isLightMode
        ? {
            secondary: {
              main: '#FFA000',
              // A muted orange or gold (for secondary or highlight elements if needed)
            },
            background: {
              default: '#121212',
              // Almost black background (like the provided image)
              paper: '#1E1E1E',
              // Slightly lighter gray for component backgrounds (like chat bubbles)
            },
            text: {
              primary: '#E0E0E0', // Light gray for main text
              secondary: '#A0A0A0', // Muted gray for secondary text
            },
          }
        : {
            primary: {
              main: '#100C47',
            },
            secondary: {
              main: '#e53935',
            },
            background: {
              default: '#f7f7f7',
              paper: '#ffffff',
            },
            text: {
              primary: '#212121',
              secondary: '#757575',
            },
            action: {
              active: '#100C47',
            },
          }),
    },
  })

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          style={{
            display: 'flex',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLoggedIn ? (
          <UserSubscriptionProvider>
            <SidebarProvider>
              <App
                isLightMode={isLightMode}
                handleDarkLightModeChange={handleDarkLightModeChange}
              />
            </SidebarProvider>
          </UserSubscriptionProvider>
        ) : (
          <SidebarProvider>
            <App isLightMode={isLightMode} handleDarkLightModeChange={handleDarkLightModeChange} />
          </SidebarProvider>
        )}
      </ThemeProvider>
    </React.Fragment>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <RollbarErrorBoundary>
      <BrowserRouter>
        <AuthProvider>
          <AppWrapper />
        </AuthProvider>
      </BrowserRouter>
    </RollbarErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
