export const DietaryRequirements = [
  'Vegetarian',
  'Vegan',
  'Pescatarian',
  'Gluten-free',
  'Dairy-free',
  'Nut-free',
  'Soy-free',
  'Shellfish-free',
  'Halal',
  'Kosher',
  'Paleo',
  'Ketogenic',
] as const;
