import React from 'react'
import { Provider, ErrorBoundary } from '@rollbar/react'

type Props = {
  children: React.ReactNode
}

const FallbackUI: React.FC = () => <div>Oops, something went wrong</div>

const ENV = process.env.REACT_APP_ENV
const useRollbar = ENV === 'development' || ENV === 'production'

if (useRollbar && !process.env.REACT_APP_ROLLBAR_CLIENT_KEY) {
  throw new Error('REACT_APP_ROLLBAR_CLIENT_KEY is not set.')
}

if (useRollbar && !process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA) {
  throw new Error('REACT_APP_VERCEL_GIT_COMMIT_SHA is not set.')
}

const RollbarErrorBoundary: React.FC<Props> = ({ children }) => {
  // Only set up the Rollbar configuration for development and production.
  const rollbarConfig = useRollbar
    ? {
        accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_KEY,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: ENV,
        payload: {
          client: {
            javascript: {
              code_version: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
              source_map_enabled: true,
            },
          },
        },
      }
    : undefined

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>
    </Provider>
  )
}

export default RollbarErrorBoundary
