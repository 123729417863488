import React from 'react'
import { ShoppingListIngredient } from 'common'
import { Box } from '@mui/system'

type Props = {
  ingredients: (ShoppingListIngredient & { checked: boolean })[]
  onIngredientClick: (index: number) => void
}

const IngredientList: React.FC<Props> = ({ ingredients, onIngredientClick }) => {
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '1rem',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      {ingredients.map((ingredient, index) => (
        <Box
          onClick={() => onIngredientClick(index)}
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '1rem',
            padding: '1rem',
            borderRadius: '5px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s ease-in-out',
            cursor: 'pointer',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '80%' }}>
            <p style={{ fontSize: '1.2rem', marginBottom: '0.5rem', overflowWrap: 'break-word' }}>
              {ingredient.ingredient}
            </p>
            <p style={{ marginBottom: 0 }}>{ingredient.quantity}</p>
          </div>
          <div
            style={{
              width: '3rem',
              height: '3rem',
              borderRadius: '50%',
              background: ingredient.checked ? '#3CB371' : '#fff',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
          >
            {ingredient.checked && (
              <svg viewBox='0 0 16 16' width='16px' height='16px' style={{ fill: '#fff' }}>
                <path d='M6 11.22L1.83 7l-1.41 1.41L6 14.03 18 2l-1.41-1.41z' />
              </svg>
            )}
          </div>
        </Box>
      ))}
    </Box>
  )
}

export default IngredientList
