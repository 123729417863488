import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormGroup,
  InputAdornment,
  IconButton,
} from '@mui/material'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import { Visibility, VisibilityOff } from '@mui/icons-material'

type Errors = Record<string, { show: boolean; msg?: string }>

type SignUpProps = {
  setShowLogin: (show: boolean) => void
  errors: Errors
  setErrors: (errors: Errors) => void
  onSubmit: (email: string, password: string, confirmPassword: string) => Promise<void>
}

const SignUp: React.FC<SignUpProps> = ({ setShowLogin, errors, setErrors, onSubmit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isSigningUp, setIsSigningUp] = useState(false)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
      <Typography variant='h4' align='center'>
        Sign Up
      </Typography>
      <FormControl
        component='form'
        onSubmit={async (e) => {
          e.preventDefault()
          setIsSigningUp(true)
          await onSubmit(email, password, confirmPassword)
          setIsSigningUp(false)
        }}
        fullWidth
      >
        <FormGroup>
          <Box sx={{ pt: 2, width: '100%' }}>
            <ResponsiveTextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                if (errors.email?.show) {
                  setErrors({})
                }
              }}
              error={errors.email?.show}
              helperText={errors.email?.msg}
              label='Email'
              fullWidth
            />
          </Box>

          <Box sx={{ pt: 2, width: '100%' }}>
            <ResponsiveTextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password?.show}
              helperText={errors.password?.msg}
              label='Password'
              type={showPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box sx={{ pt: 2, width: '100%' }}>
            <ResponsiveTextField
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value)
                if (errors.confirmPassword?.show) {
                  setErrors({})
                }
              }}
              error={errors.confirmPassword?.show}
              helperText={errors.confirmPassword?.msg}
              label='Confirm Password'
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge='end'
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
            '& .MuiButton-root': {
              textTransform: 'none',
            },
          }}
        >
          <Button
            variant='contained'
            type='submit'
            disabled={
              !email.length ||
              !password.length ||
              !confirmPassword.length ||
              !!Object.keys(errors).length
            }
          >
            {!isSigningUp ? `Sign up` : 'Signing up...'}
          </Button>
          <Button variant='contained' color='primary' onClick={() => setShowLogin(true)}>
            Already have an account? Log in
          </Button>
        </Box>
      </FormControl>
    </Box>
  )
}

export default SignUp
