import React, { useEffect, useRef, useState } from 'react'
import { Box, Container, Paper } from '@mui/material'
import ViewRecipe from '../common/large-recipe-card'
import MealType from '../common/components/meal-type'
import CreateRecipeButton from '../common/components/create-recipe-button'
import Serves from '../common/components/serves'
import StoragePreference from '../common/components/storage-preference'
import CustomSnackbar from '../common/custom-snackbar'
import OverrideUserPreference from '../common/components/override-user-preference'
import useUserPreference from '../profile/useUserPreference.hook'
import { useRecipeCreate } from '../contexts/useRecipeCreate'
import LoadingLargeRecipeCard from '../common/loading-large-recipe-card'
import FreeTierBanner from '../common/free-tier-banner'

const BatchBliss = () => {
  const [serves, setServes] = useState(7)
  const [mealType, setMealType] = useState('dinner')
  const [storagePreferences, setStoragePreferences] = useState(['freezer'])

  const { userPreference, overrideUserPreference, isLoadingUserPreference } = useUserPreference()

  const {
    handleCreateRecipe,
    showError,
    setShowError,
    recipe,
    isCreating,
    name,
    description,
    ingredients,
    kitchenTools,
    instructions,
    calories,
    carbs,
    protein,
    fat,
    fiber,
    sugar,
    prepTime,
    difficulty,
    url,
    recipeServes,
  } = useRecipeCreate()

  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isCreating && cardRef.current) {
      const topPos = cardRef.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: topPos, behavior: 'smooth' })
    }
  }, [isCreating])

  return (
    <Container>
      <CustomSnackbar show={showError} setShow={setShowError} severity='error' />
      <FreeTierBanner type='recipe' />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ width: '100%', p: 3, mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Serves
              sx={{ width: '100%' }}
              label='How many servings do you want to make?'
              serves={serves}
              onChange={setServes}
            />

            <StoragePreference
              sx={{ width: '100%', mt: 3 }}
              storagePreferences={storagePreferences}
              onChange={setStoragePreferences}
            />

            <MealType sx={{ width: '100%', mt: 3 }} mealType={mealType} onChange={setMealType} />
            <OverrideUserPreference
              userPreference={userPreference}
              onChange={overrideUserPreference}
            />
          </Box>
        </Paper>
        <CreateRecipeButton
          onClick={() =>
            handleCreateRecipe({
              userPreference,
              recipeMode: {
                type: 'batchBliss',
                mealType,
                storage: storagePreferences,
                serves: serves.toString(),
              },
            })
          }
          isLoading={isCreating}
          disabled={isLoadingUserPreference}
        />
        {isCreating && (
          <LoadingLargeRecipeCard
            cardRef={cardRef}
            name={name}
            description={description}
            prepTime={prepTime}
            difficulty={difficulty}
            serves={recipeServes}
            ingredients={ingredients}
            kitchenTools={kitchenTools}
            instructions={instructions}
            calories={calories}
            carbs={carbs}
            protein={protein}
            fat={fat}
            fiber={fiber}
            sugar={sugar}
            url={url}
          />
        )}
        {!isCreating && recipe && <ViewRecipe recipe={recipe} />}{' '}
      </Box>
    </Container>
  )
}

export default BatchBliss
