import { Typography } from '@mui/material'
import { FeedbackWithCommentsAndFiles } from 'common'
import React from 'react'

type FeedbackMetaInfoProps = {
  feedback: FeedbackWithCommentsAndFiles
}

const FeedbackMetaInfo: React.FC<FeedbackMetaInfoProps> = ({ feedback }) => {
  return (
    <React.Fragment>
      <Typography color='textSecondary' marginBottom='0.5em'>
        Git Commit Hash: {feedback.gitCommitHash || 'N/A'}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        Page URL: {feedback.pageURL || 'N/A'}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        IP Address: {feedback.ipAddress || 'N/A'}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        User Agent: {feedback.userAgent}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        Screen Size: {feedback.screenSize}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        Platform: {feedback.platform}
      </Typography>
      <Typography color='textSecondary' marginBottom='0.5em'>
        userId: {feedback.userId || 'N/A'}
      </Typography>
    </React.Fragment>
  )
}

export default FeedbackMetaInfo
