import React, { useState, forwardRef } from 'react'
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardMedia,
  IconButton,
  CardHeader,
  FormControlLabel,
  Switch,
} from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Recipe } from 'common'

const NutritionCard: React.FC<{ recipe: Recipe }> = ({ recipe }) => {
  const [isPerServing, setIsPerServing] = useState(true)

  const toggleIsPerServing = () => {
    setIsPerServing(!isPerServing)
  }

  const getNutritionValue = (totalValue: number) => {
    return isPerServing ? Math.round(totalValue / recipe.serves) : totalValue
  }

  return (
    <Card
      variant='outlined'
      sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
    >
      <CardHeader
        title={
          <Box display='flex' alignItems='center'>
            Nutrition
            <Typography variant='subtitle2' sx={{ ml: 2 }}>
              {isPerServing ? '(Per Serving)' : '(Total)'}
            </Typography>
          </Box>
        }
        action={
          <FormControlLabel
            control={<Switch checked={isPerServing} onChange={toggleIsPerServing} />}
            label='View per serving'
          />
        }
      />
      <CardContent>
        <ul>
          <li>
            <Typography>Calories: {getNutritionValue(recipe.calories)}</Typography>
          </li>
          <li>
            <Typography>Carbs: {getNutritionValue(recipe.carbs)}</Typography>
          </li>
          <li>
            <Typography>Protein: {getNutritionValue(recipe.protein)}</Typography>
          </li>
          <li>
            <Typography>Fat: {getNutritionValue(recipe.fat)}</Typography>
          </li>
          <li>
            <Typography>Fiber: {getNutritionValue(recipe.fiber)}</Typography>
          </li>
          <li>
            <Typography>Sugar: {getNutritionValue(recipe.sugar)}</Typography>
          </li>
        </ul>
      </CardContent>
    </Card>
  )
}

type LargeRecipeCardProps = {
  recipe: Recipe
}

const LargeRecipeCard: React.FC<LargeRecipeCardProps> = ({ recipe }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  type ListItemProps = React.LiHTMLAttributes<HTMLLIElement> & React.RefAttributes<HTMLLIElement>

  const ListItem = forwardRef<HTMLLIElement, ListItemProps>((props, ref) => (
    <li ref={ref} {...props} />
  ))

  ListItem.displayName = 'ListItem'

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 1024,
        borderRadius: 3,
        boxShadow: 2,
        '&:hover': {
          boxShadow: 10,
          cursor: 'pointer',
        },
      }}
    >
      <div style={{ position: 'relative' }}>
        <CardMedia
          component='img'
          height={isExpanded ? '1024' : '240'}
          image={
            recipe.url || 'https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg'
          }
          alt={recipe.name}
          style={{ width: '100%' }}
        />
        {!isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandMore color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
        {isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandLess color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
      </div>

      <CardContent>
        <Typography variant='h4' component='div' sx={{ fontWeight: 'bold' }}>
          {recipe.name}
        </Typography>
        <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
          {recipe.description}
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={4}>
            <Typography color='text.secondary'>Time: {recipe.prepTime} minutes</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary'>Difficulty: {recipe.difficulty}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary'> Serves: {recipe.serves}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} md={6}>
            <Card
              variant='outlined'
              sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
            >
              <CardHeader title='Ingredients' />
              <CardContent>
                <ul>
                  {recipe.ingredients.map((ingredient, index) => (
                    <ListItem key={index}>{ingredient}</ListItem>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              variant='outlined'
              sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
            >
              <CardHeader title='Kitchen tools needed' />
              <CardContent>
                <ul>
                  {recipe.kitchenTools.map((tool, index) => (
                    <ListItem key={index}>{tool}</ListItem>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: 2 }}>
          <Card
            variant='outlined'
            sx={{ border: '1px solid', borderColor: 'divider', boxShadow: 2, borderRadius: 2 }}
          >
            <CardHeader title='Instructions' />
            <CardContent>
              <ol>
                {recipe.instructions.map((instruction, index) => (
                  <ListItem key={index}>{instruction}</ListItem>
                ))}
              </ol>
            </CardContent>
          </Card>
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <NutritionCard recipe={recipe} />
        </Box>
      </CardContent>
    </Card>
  )
}

export default LargeRecipeCard
