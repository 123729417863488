import React, { useEffect, useRef, useState } from 'react'
import { Box, Container, Paper } from '@mui/material'
import ViewRecipe from '../common/large-recipe-card'
import CreateRecipeButton from '../common/components/create-recipe-button'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import CustomSnackbar from '../common/custom-snackbar'
import useUserPreference from '../profile/useUserPreference.hook'
import OverrideUserPreference from '../common/components/override-user-preference'
import LoadingLargeRecipeCard from '../common/loading-large-recipe-card'
import { useRecipeCreate } from '../contexts/useRecipeCreate'
import FreeTierBanner from '../common/free-tier-banner'
import SinglePickBox from '../common/components/single-pick-box'
import ToggleChoiceBox from '../common/components/toggle-choice-box'

const PantryChef = () => {
  const [pantryIngredients, setPantryIngredients] = useState('')
  const [howToUseIngredients, setHowToUseIngredients] = useState<'all' | 'best'>('all')
  const [useIngredientsNotInPantry, setUseIngredientsNotInPantry] = useState<boolean>(false)

  const { userPreference, overrideUserPreference, isLoadingUserPreference } = useUserPreference()

  const {
    handleCreateRecipe,
    showError,
    setShowError,
    recipe,
    isCreating,
    name,
    description,
    ingredients,
    kitchenTools,
    instructions,
    calories,
    carbs,
    protein,
    fat,
    fiber,
    sugar,
    prepTime,
    difficulty,
    url,
    recipeServes,
  } = useRecipeCreate()

  const cardRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isCreating && cardRef.current) {
      const topPos = cardRef.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: topPos, behavior: 'smooth' })
    }
  }, [isCreating])

  return (
    <Container>
      <CustomSnackbar show={showError} setShow={setShowError} severity='error' />
      <FreeTierBanner type='recipe' />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Paper sx={{ width: '100%', p: 3, mt: 3 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <ResponsiveTextField
              fullWidth
              label='What ingredients do you have?'
              helperText='Include quantities for better results, e.g. 300g beef, 100ml milk'
              value={pantryIngredients}
              onChange={(e) => setPantryIngredients(e.target.value)}
            />

            <SinglePickBox
              sx={{ width: '100%', mt: 3 }}
              options={[
                { value: 'all', label: 'Include every listed ingredient' },
                { value: 'best', label: 'Select most suitable ingredients only' },
              ]}
              selectedOption={howToUseIngredients}
              label='How would you like to use your ingredients?'
              onSelect={(e) => setHowToUseIngredients(e as 'all' | 'best')}
            />

            <ToggleChoiceBox
              sx={{ width: '100%', mt: 3 }}
              label='Include ingredients not in my pantry'
              isChecked={useIngredientsNotInPantry}
              onToggle={() => setUseIngredientsNotInPantry((prev) => !prev)}
            />

            <OverrideUserPreference
              userPreference={userPreference}
              onChange={overrideUserPreference}
            />
          </Box>
        </Paper>
        <CreateRecipeButton
          onClick={() =>
            handleCreateRecipe({
              userPreference,
              recipeMode: {
                type: 'pantryChef',
                ingredients: pantryIngredients,
                howToUseIngredients,
                useIngredientsNotInPantry,
              },
            })
          }
          isLoading={isCreating}
          disabled={isLoadingUserPreference || !pantryIngredients}
        />
        {isCreating && (
          <LoadingLargeRecipeCard
            cardRef={cardRef}
            name={name}
            description={description}
            prepTime={prepTime}
            difficulty={difficulty}
            serves={recipeServes}
            ingredients={ingredients}
            kitchenTools={kitchenTools}
            instructions={instructions}
            calories={calories}
            carbs={carbs}
            protein={protein}
            fat={fat}
            fiber={fiber}
            sugar={sugar}
            url={url}
          />
        )}
        {!isCreating && recipe && <ViewRecipe recipe={recipe} />}{' '}
      </Box>
    </Container>
  )
}

export default PantryChef
