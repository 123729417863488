import React, { useState } from 'react'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { customMaterialUiUtil } from './util'
import AudioRecorder from '../audio-recorder'
import { Close } from '@mui/icons-material'

type ResponsiveTextFieldProps = TextFieldProps & {
  labelFontSizeWhenInMiddle?: number
  labelFontSizeWhenAbove?: number
  onVoiceInput?: (value: string) => void
  onClear?: () => void
  borderProps?: React.CSSProperties
}

const ResponsiveTextField: React.FC<ResponsiveTextFieldProps> = ({
  labelFontSizeWhenAbove = 23,
  labelFontSizeWhenInMiddle = 18,
  onVoiceInput,
  onChange,
  onClear,
  label,
  borderProps,
  ...props
}) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isDarkMode = theme.palette.mode === 'dark'
  const [isFocused, setIsFocused] = useState(false)

  const fontSize = customMaterialUiUtil.useResponsiveFontSize(labelFontSizeWhenAbove)
  const top = customMaterialUiUtil.calculateTop(fontSize)

  const hasValue =
    typeof props.value === 'number' ||
    (typeof props.value === 'string' && props.value !== '') ||
    (Array.isArray(props.value) && props.value.length > 0)

  const endAdornments = (
    <>
      {props.InputProps?.endAdornment && props.InputProps?.endAdornment}
      {onClear && hasValue && (
        <InputAdornment position='end'>
          <IconButton onClick={onClear} edge='end'>
            <Close />
          </IconButton>
        </InputAdornment>
      )}
      {onVoiceInput && <AudioRecorder sx={{ zIndex: 10 }} setText={onVoiceInput} />}
    </>
  )

  return (
    <>
      {isSmallScreen && label && (
        <div
          style={{
            width: '100%',
            marginBottom: '8px',
            fontSize: 16, // Use the default font size
            fontWeight: 400, // Regular font weight
            color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
            transformOrigin: 'top left',
            pointerEvents: 'none', // Make sure it doesn't interfere with events
            userSelect: 'none', // Prevent text selection
          }}
        >
          {label}
        </div>
      )}
      <TextField
        {...props}
        label={isSmallScreen ? undefined : label}
        onChange={onChange}
        InputLabelProps={{
          ...props.InputLabelProps,
          style: {
            fontSize: isFocused
              ? `${fontSize}px`
              : hasValue
              ? `${fontSize}px`
              : labelFontSizeWhenInMiddle,
            top: `${top}px`,
            transition: 'all 0.3s ease',
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...props.InputLabelProps?.style,
          },
        }}
        InputProps={{
          ...props.InputProps,
          endAdornment: endAdornments,
        }}
        onFocus={(e) => {
          setIsFocused(true)
          if (props.onFocus) props.onFocus(e)
        }}
        onBlur={(e) => {
          setIsFocused(false)
          if (props.onBlur) props.onBlur(e)
        }}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
            fontSize: isFocused
              ? `${fontSize}px`
              : hasValue
              ? `${fontSize}px`
              : labelFontSizeWhenInMiddle,
            transition: 'all 0.3s ease',
            ...borderProps,
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
          ...props.sx,
        }}
      />
    </>
  )
}

export default ResponsiveTextField
