import React, { useState, useEffect, PropsWithChildren, createContext, useContext } from 'react'
import { userSubscriptionService } from '../profile/user-subscription.service'
import { SubscriptionType, TierLimits } from 'common'

interface UserSubscriptionContextType {
  currentSubscriptionType: SubscriptionType
  currentEndDate: Date | null
  trialAllowed: boolean
  billingPeriod: 'monthly' | 'yearly' | null
  isLoading: boolean
  tierLimits: TierLimits | null
  atRecipeLimit: boolean
  atShoppingListLimit: boolean
  checkAndUpdateSubscription: () => Promise<void>
  updateLimits: () => Promise<void>
}

export const UserSubscriptionContext = createContext<UserSubscriptionContextType>({
  currentSubscriptionType: 'free',
  currentEndDate: null,
  trialAllowed: false,
  billingPeriod: null,
  isLoading: true,
  atRecipeLimit: false,
  atShoppingListLimit: false,
  tierLimits: null,
  checkAndUpdateSubscription: () => Promise.resolve(undefined),
  updateLimits: () => Promise.resolve(undefined),
})

export const useUserSubscription = () => {
  const context = useContext(UserSubscriptionContext)
  if (!context) {
    throw new Error('useUserSubscriptionContext must be used within a UserSubscriptionProvider')
  }
  return context
}

export const UserSubscriptionProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [currentSubscriptionType, setCurrentSubscriptionType] = useState<SubscriptionType>('free')
  const [currentEndDate, setCurrentEndDate] = useState<Date | null>(null)
  const [trialAllowed, setTrialAllowed] = useState<boolean>(false)
  const [billingPeriod, setBillingPeriod] = useState<'monthly' | 'yearly' | null>(null)
  const [tierLimits, setTierLimits] = useState<TierLimits | null>(null)
  const [atRecipeLimit, setAtRecipeLimit] = useState<boolean>(false)
  const [atShoppingListLimit, setAtShoppingListLimit] = useState<boolean>(false)

  const checkAndUpdateSubscription = async () => {
    const userSubscription = await userSubscriptionService.checkAndUpdateUserSubscription()

    const { currentSubscriptionType, currentEndDate, trialAllowed, billingPeriod, tierLimits } =
      userSubscription

    setCurrentSubscriptionType(currentSubscriptionType)
    setCurrentEndDate(currentEndDate ? new Date(currentEndDate) : null)
    setTrialAllowed(trialAllowed)
    setBillingPeriod(billingPeriod)
    setTierLimits(tierLimits)
    setAtRecipeLimit(tierLimits.currentMonthlyRecipes >= tierLimits.maxMonthlyRecipes)
    setAtShoppingListLimit(
      tierLimits.currentMonthlyShoppingLists >= tierLimits.maxMonthlyShoppingLists,
    )
  }

  const updateLimits = async () => {
    const { tierLimits } = await userSubscriptionService.getUserSubscription()

    setTierLimits(tierLimits)
    setAtRecipeLimit(tierLimits.currentMonthlyRecipes >= tierLimits.maxMonthlyRecipes)
    setAtShoppingListLimit(
      tierLimits.currentMonthlyShoppingLists >= tierLimits.maxMonthlyShoppingLists,
    )
  }

  useEffect(() => {
    setIsLoading(true)
    const fetchSubscription = async () => {
      try {
        const { currentSubscriptionType, currentEndDate, trialAllowed, billingPeriod, tierLimits } =
          await userSubscriptionService.getUserSubscription()

        setCurrentSubscriptionType(currentSubscriptionType)
        setCurrentEndDate(currentEndDate ? new Date(currentEndDate) : null)
        setTrialAllowed(trialAllowed)
        setBillingPeriod(billingPeriod)
        setTierLimits(tierLimits)
        setAtRecipeLimit(tierLimits.currentMonthlyRecipes >= tierLimits.maxMonthlyRecipes)
        setAtShoppingListLimit(
          tierLimits.currentMonthlyShoppingLists >= tierLimits.maxMonthlyShoppingLists,
        )
      } catch (err) {
        console.log(err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchSubscription()
  }, [])

  return (
    <UserSubscriptionContext.Provider
      value={{
        currentSubscriptionType,
        currentEndDate,
        trialAllowed,
        billingPeriod,
        isLoading,
        tierLimits,
        atRecipeLimit,
        atShoppingListLimit,
        checkAndUpdateSubscription,
        updateLimits,
      }}
    >
      {children}
    </UserSubscriptionContext.Provider>
  )
}
