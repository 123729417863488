import { MenuItem, Select } from '@mui/material'
import { deepOrange, green, grey, lightBlue, yellow } from '@mui/material/colors'
import { FeedbackStatus } from 'common'
import React from 'react'

const getStatusColor = () => 'white'

const getStatusBackgroundColor = (status: FeedbackStatus) => {
  switch (status) {
    case 'pending':
      return deepOrange[500] // A deep orange color
    case 'reviewed':
      return lightBlue[500] // A light blue color
    case 'inProgress':
      return yellow[700] // A deeper yellow, for better contrast with white text
    case 'resolved':
      return green[500] // A medium green
    default:
      return grey[500] // A medium grey
  }
}

export const FeedbackStatusDropdown = ({
  feedbackId,
  currentStatus,
  onChange,
}: {
  feedbackId: string
  currentStatus: FeedbackStatus
  onChange: (feedbackId: string, status: FeedbackStatus) => void
}) => {
  return (
    <Select
      size='small'
      value={currentStatus}
      onChange={(e) => onChange(feedbackId, e.target.value as FeedbackStatus)}
      style={{
        color: getStatusColor(),
        backgroundColor: getStatusBackgroundColor(currentStatus),
        borderRadius: '2px',
      }}
    >
      <MenuItem
        value='pending'
        style={{
          color: getStatusColor(),
          backgroundColor: getStatusBackgroundColor('pending'),
        }}
      >
        Pending
      </MenuItem>
      <MenuItem
        value='reviewed'
        style={{
          color: getStatusColor(),
          backgroundColor: getStatusBackgroundColor('reviewed'),
        }}
      >
        Reviewed
      </MenuItem>

      <MenuItem
        value='inProgress'
        style={{
          color: getStatusColor(),
          backgroundColor: getStatusBackgroundColor('inProgress'),
        }}
      >
        In Progress
      </MenuItem>

      <MenuItem
        value='resolved'
        style={{
          color: getStatusColor(),
          backgroundColor: getStatusBackgroundColor('resolved'),
        }}
      >
        Resolved
      </MenuItem>
    </Select>
  )
}

export default FeedbackStatusDropdown
