export const Allergies = [
  'Gluten',
  'Dairy',
  'Nuts',
  'Soy',
  'Eggs',
  'Fish',
  'Shellfish',
  'Wheat',
  'Peanuts',
  'Tree nuts',
] as const;
