import React from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  FormControlProps,
  SelectProps,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { customMaterialUiUtil } from './util'

type ResponsiveSelectProps<T> = Omit<FormControlProps, 'onChange'> & {
  label: string
  labelFontSizeWhenAbove?: number
  value: T
  onChange: (value: T) => void
  formControlProps?: FormControlProps
  selectProps?: SelectProps
}

const ResponsiveSelect = <T,>({
  label,
  labelFontSizeWhenAbove = 23,
  value,
  onChange,
  children,
  sx,
  selectProps,
  formControlProps,
}: ResponsiveSelectProps<T>) => {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const isDarkMode = theme.palette.mode === 'dark'

  const fontSize = customMaterialUiUtil.useResponsiveFontSize(labelFontSizeWhenAbove)
  const top = customMaterialUiUtil.calculateTop(fontSize)

  return (
    <FormControl sx={sx} {...formControlProps}>
      {isSmallScreen && label && (
        <div
          style={{
            width: '100%',
            marginBottom: '8px',
            fontSize: 16, // Use the default font size
            fontWeight: 400, // Regular font weight
            color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)',
            transformOrigin: 'top left',
            pointerEvents: 'none', // Make sure it doesn't interfere with events
            userSelect: 'none', // Prevent text selection
          }}
        >
          {label}
        </div>
      )}
      <InputLabel
        className='MuiFormLabel-root'
        id={`${label}-label`}
        sx={{
          fontSize: fontSize,
          top: top,
          transition: 'all 0.3s ease',
        }}
      >
        {isSmallScreen ? undefined : label}
      </InputLabel>
      <Select
        {...selectProps}
        label={isSmallScreen ? undefined : label}
        value={value}
        onChange={(e) => onChange(e.target.value as T)}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
            fontSize: fontSize,
            transition: 'all 0.3s ease',
          },
          '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
        }}
      >
        {children}
      </Select>
    </FormControl>
  )
}

export default ResponsiveSelect
