export const KitchenTools = [
  'Stove',
  'Oven',
  'Microwave',
  'Toaster',
  'Blender',
  'Coffee Maker',
  'Food Processor',
  'Mixer (Stand/Hand)',
  'Rice Cooker',
  'Electric Griddle',
  'Electric Skillet',
  'Grill',
  'Juicer',
  'Espresso Machine',
  'Panini Press',
  'Waffle Maker',
  'Popcorn Maker',
  'Ice Cream Maker',
  'Steamer',
  'Dehydrator',
  'Pizza Oven',
  'Egg Cooker',
  'Crepe Maker',
  'Milk Frother',
  'Pasta Maker',
  'Rotisserie',
  'Sous Vide Machine',
  'Raclette Grill',
  'Teppanyaki Grill',
  'Hot Pot',
  'Bread Machine',
] as const;
