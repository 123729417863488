import { UserPreference } from 'common'
import { httpService } from '../services/http.service'

const get = async () => {
  const { userProfile } = await httpService.post({
    url: '/get-user-profile',
  })

  return userProfile
}

const update = async ({ userPreference }: { userPreference: Partial<UserPreference> }) => {
  const { userProfile } = await httpService.post({
    url: '/update-user-profile',
    data: userPreference,
  })

  return userProfile
}

export const userProfileService = {
  get,
  update,
}
