import { ErrorOutline, CheckCircle } from '@mui/icons-material'
import { Alert, Snackbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

type SnackbarProps = {
  show: boolean
  setShow: (value: boolean) => void
  textMain?: string
  textSecondary?: string
  severity: 'error' | 'success'
}

const CustomSnackbar: React.FC<SnackbarProps> = ({
  show,
  setShow,
  textMain,
  textSecondary = '',
  severity,
}) => {
  const icon =
    severity === 'error' ? (
      <ErrorOutline fontSize='inherit' sx={{ color: 'white' }} />
    ) : (
      <CheckCircle fontSize='inherit' sx={{ color: 'white' }} />
    )
  const backgroundColor = severity === 'error' ? 'error.dark' : 'success.main'

  if (!textMain) {
    textMain = severity === 'error' ? 'An error has occurred' : 'Success'
  }

  if (!textSecondary) {
    textSecondary = severity === 'error' ? 'Please try again' : ''
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={show}
      autoHideDuration={3000}
      onClose={() => setShow(false)}
    >
      <Alert
        onClose={() => setShow(false)}
        severity={severity}
        sx={{
          backgroundColor: backgroundColor,
          color: 'common.white',
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
        }}
        icon={icon}
      >
        <Box
          sx={{
            padding: '0.5rem 1rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          }}
        >
          <Typography
            component='span'
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
              letterSpacing: 0.5,
            }}
          >
            {textMain}
          </Typography>
          <Typography
            component='span'
            sx={{
              fontSize: '1rem',
              letterSpacing: 0.3,
              marginTop: '0.2rem',
            }}
          >
            {textSecondary}
          </Typography>
        </Box>
      </Alert>
    </Snackbar>
  )
}

export default CustomSnackbar
