import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faUtensils,
  faBoxOpen,
  faGift,
  faHeartbeat,
  faGlobeAmericas,
  faUsers,
  faBirthdayCake,
  faRandom,
  faBlender,
  faBookOpen,
  faKitchenSet,
  fa5,
} from '@fortawesome/free-solid-svg-icons'

export type AppMenuItem = {
  key: string
  icon: IconDefinition
  requiresPremium?: boolean
}

export const menuItems: AppMenuItem[] = [
  { key: 'simple-chef', icon: faUtensils, requiresPremium: false },
  { key: 'pantry-chef', icon: faBoxOpen, requiresPremium: false },
  { key: 'family-chef', icon: faUsers, requiresPremium: false },
  { key: 'surprise-chef', icon: faGift, requiresPremium: true },
  { key: 'health-chef', icon: faHeartbeat, requiresPremium: true },
  { key: 'global-chef', icon: faGlobeAmericas, requiresPremium: true },
  { key: 'festive-chef', icon: faBirthdayCake, requiresPremium: true },
  { key: 'fusion-frenzy', icon: faRandom, requiresPremium: true },
  { key: 'five-ingredient-fix', icon: fa5, requiresPremium: true },
  { key: 'batch-bliss', icon: faBlender, requiresPremium: true },
  { key: 'skill-sharpening', icon: faKitchenSet, requiresPremium: true },
  { key: 'my-recipes', icon: faBookOpen, requiresPremium: false },
]

export const adminMenuItems: AppMenuItem[] = [{ key: 'admin', icon: faUtensils }]

export const keyLabels: Record<string, string> = {
  'simple-chef': 'Simple Chef',
  'pantry-chef': 'Pantry Chef',
  'surprise-chef': 'Surprise Chef',
  'health-chef': 'Health Chef',
  'global-chef': 'Global Chef',
  'family-chef': 'Family Chef',
  'festive-chef': 'Festive Chef',
  'fusion-frenzy': 'Fusion Frenzy',
  'five-ingredient-fix': 'Five Ingredient Fix',
  'batch-bliss': 'Batch Bliss',
  'skill-sharpening': 'Skill Sharpening',
  'my-recipes': 'My Recipes',
  profile: 'Profile',
  admin: 'Admin',
  'admin/feedback': 'Feedback',
}
