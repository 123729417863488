import React from 'react'
import { Checkbox, Paper, SxProps, Typography } from '@mui/material'

interface ToggleChoiceBoxProps {
  sx?: SxProps
  label: string
  isChecked: boolean
  onToggle: () => void
}

const ToggleChoiceBox: React.FC<ToggleChoiceBoxProps> = ({ sx, label, isChecked, onToggle }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        p: 2,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={onToggle}
    >
      <Checkbox sx={{ p: 0, mr: 1 }} checked={isChecked} color='primary' />

      <Typography variant='body1'>{label}</Typography>
    </Paper>
  )
}

export default ToggleChoiceBox
