import React from 'react'
import { InsertDriveFile } from '@mui/icons-material'
import { Box, useTheme } from '@mui/system'
import { Link, Typography, useMediaQuery } from '@mui/material'

type ViewFileProps = {
  file: {
    fileUrl: string
    originalName: string
    mimeType: string
  }
}

const ViewFile: React.FC<ViewFileProps> = ({ file }) => {
  const isImage = file.mimeType.startsWith('image/')
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const filePreviewStyle = {
    display: 'flex',
    flexDirection: isSmallScreen ? 'column' : 'row',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    border: '1px solid #e0e0e0',
    borderRadius: '5px',
    padding: '1rem',
  } as const

  const imagePreviewStyle = {
    maxWidth: '100%',
    width: isSmallScreen ? '100px' : '300px',
    height: isSmallScreen ? '100px' : '300px',
    border: '1px solid #ddd',
    borderRadius: '5px',
    flexShrink: 0,
    objectFit: 'cover',
  } as const

  const fileIconStyle = {
    fontSize: '48px',
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  } as const

  const fileNameStyle = {
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    flexGrow: 1,
  } as const

  return (
    <Box sx={filePreviewStyle}>
      {isImage ? (
        <>
          <img
            src={file.fileUrl}
            alt={`Preview of ${file.originalName}`}
            style={imagePreviewStyle}
          />
          <Typography variant='subtitle1' sx={fileNameStyle}>
            {file.originalName}
          </Typography>
        </>
      ) : (
        <>
          <InsertDriveFile style={fileIconStyle} />
          <Box>
            <Link
              href={file.fileUrl}
              target='_blank'
              rel='noopener noreferrer'
              variant='subtitle1'
              sx={fileNameStyle}
            >
              {file.originalName}
            </Link>
            <Typography variant='body2' color='textSecondary'>
              {file.mimeType}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default ViewFile
