import { SxProps } from '@mui/material'
import React from 'react'
import ResponsiveAutoComplete from '../custom-material-ui/responsive-autocomplete'

const occasionTypes = [
  'Baby Shower',
  'Backyard BBQ',
  'Birthday',
  'Bridal Shower',
  'Brunch',
  'Christmas',
  'Cocktail Party',
  'Diwali',
  'Easter',
  'Engagement Party',
  'Family Reunion',
  'Farewell Party',
  `Father's Day`,
  'Game Night',
  'Graduation',
  'Halloween',
  'Hanukkah',
  'Housewarming',
  `Mother's Day`,
  `New Year's Eve`,
  'Office Party',
  'Picnic',
  'Potluck',
  'Ramadan Iftar',
  'Romantic Date',
  `St. Patrick's Day`,
  'Summer Party',
  'Super Bowl Party',
  'Thanksgiving',
  `Valentine's Day`,
  'Wedding',
  'Wedding Anniversary',
]

type OccasionTypeProps = {
  sx?: SxProps
  occasionType: string
  onChange: (value: string) => void
}

const OccasionType: React.FC<OccasionTypeProps> = ({ sx, occasionType, onChange }) => {
  return (
    <ResponsiveAutoComplete
      sx={sx}
      value={occasionType}
      onChange={(event, newValue) => newValue && onChange(newValue)}
      options={occasionTypes}
      label='Occasion type'
    />
  )
}

export default OccasionType
