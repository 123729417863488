import React, { useContext } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { UserSubscriptionContext } from '../contexts/useUserSubscription'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../auth-context'

type LimitsBannerProps = {
  type: 'recipe' | 'shoppingList'
}

const LimitsBanner: React.FC<LimitsBannerProps> = ({ type }) => {
  const { currentSubscriptionType, tierLimits } = useContext(UserSubscriptionContext)
  const { isAdmin } = useContext(AuthContext)
  const nav = useNavigate()

  if (!tierLimits || isAdmin) {
    return null
  }

  const renderInfo = () => {
    const {
      currentMonthlyRecipes,
      maxMonthlyRecipes,
      currentMonthlyShoppingLists,
      maxMonthlyShoppingLists,
    } = tierLimits

    if (type === 'recipe' && currentSubscriptionType === 'free') {
      return (
        <Typography variant='body2' color='textSecondary'>
          {`You've created ${currentMonthlyRecipes} of 
          ${maxMonthlyRecipes} free recipes this month.`}
        </Typography>
      )
    }

    if (type === 'shoppingList' && currentSubscriptionType === 'free') {
      return (
        <Typography variant='body2' color='textSecondary'>
          {`You've created ${currentMonthlyShoppingLists} of 
          ${maxMonthlyShoppingLists} free shopping lists this month.`}
        </Typography>
      )
    }

    if (type === 'recipe' && currentSubscriptionType !== 'free') {
      return (
        <Typography variant='body2' color='textSecondary'>
          {`You have ${
            maxMonthlyRecipes - currentMonthlyRecipes >= 1
              ? maxMonthlyRecipes - currentMonthlyRecipes
              : 'no'
          } recipes remaining for the month.`}
        </Typography>
      )
    }

    if (type === 'shoppingList' && currentSubscriptionType !== 'free') {
      return (
        <Typography variant='body2' color='textSecondary'>
          {`You have ${
            maxMonthlyShoppingLists - currentMonthlyShoppingLists >= 1
              ? maxMonthlyShoppingLists - currentMonthlyShoppingLists
              : 'no'
          } shopping lists remaining for the month.`}
        </Typography>
      )
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
        borderRadius: '5px',
      }}
    >
      {renderInfo()}
      {currentSubscriptionType === 'free' && (
        <Button
          variant='text'
          color='primary'
          onClick={() => nav('/profile/subscription')}
          sx={{ textTransform: 'none' }}
        >
          Upgrade for more!
        </Button>
      )}
      {currentSubscriptionType === 'plus' && (
        <Button
          variant='text'
          color='primary'
          onClick={() => nav('/profile/subscription')}
          sx={{ textTransform: 'none' }}
        >
          Upgrade to pro for more!
        </Button>
      )}
    </Box>
  )
}

export default LimitsBanner
