import React, { useEffect, useRef, useState } from 'react'
import { Card, CardContent, Typography, Box, Grid, CardMedia, IconButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Recipe, ShoppingList } from 'common'
import IngredientList from '../my-shopping-list/ingredient-list-item'

type LargeShoppingListCardProps = {
  shoppingList: ShoppingList
  recipe: Recipe
}

const LargeShoppingListCard: React.FC<LargeShoppingListCardProps> = ({ recipe, shoppingList }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [checkedIngredients, setCheckedIngredients] = useState(
    shoppingList.ingredients.map((i) => ({ ...i, checked: false })),
  )

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref?.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <Card
      sx={{
        width: '100%',
        maxWidth: 1024,
        borderRadius: 3,
        boxShadow: 2,
        '&:hover': {
          boxShadow: 10,
          cursor: 'pointer',
        },
      }}
    >
      <div style={{ position: 'relative' }}>
        <CardMedia
          component='img'
          height={isExpanded ? '1024' : '240'}
          image={
            recipe.url || 'https://www.ncenet.com/wp-content/uploads/2020/04/No-image-found.jpg'
          }
          alt={recipe.name}
          style={{ width: '100%' }}
        />
        {!isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandMore color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
        {isExpanded && (
          <div
            style={{
              position: 'absolute',
              bottom: 5,
              left: '97%',
              transform: 'translateX(-50%)',
              zIndex: 1,
              width: 30,
              height: 30,
              borderRadius: '50%',
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <IconButton onClick={toggleExpand}>
              <ExpandLess color='primary' style={{ fontSize: 30 }} />
            </IconButton>
          </div>
        )}
      </div>

      <CardContent>
        <Typography variant='h4' component='div' sx={{ fontWeight: 'bold' }}>
          {recipe.name}
        </Typography>
        <Typography variant='h6' component='div' sx={{ fontWeight: 'bold' }}>
          {recipe.description}
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={4}>
            <Typography color='text.secondary'>Time: {recipe.prepTime}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary'>Difficulty: {recipe.difficulty}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography color='text.secondary'> Serves: {recipe.serves}</Typography>
          </Grid>
        </Grid>
        <Typography variant='h6' component='div' sx={{ marginTop: 3, fontWeight: 'bold' }}>
          Your Shopping List
        </Typography>
        <Box ref={ref} sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}>
          <IngredientList
            ingredients={checkedIngredients}
            onIngredientClick={(i) => {
              checkedIngredients[i] = {
                ...checkedIngredients[i],
                checked: !checkedIngredients[i].checked,
              }

              setCheckedIngredients([...checkedIngredients])
            }}
          />
        </Box>
      </CardContent>
    </Card>
  )
}

export default LargeShoppingListCard
