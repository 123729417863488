import axios, { AxiosRequestConfig } from 'axios'
import { ApiRequestBody, ApiRequestResponse, RouteSSERequests, RouteSSEvents } from 'common'

const URL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3001'

type IfNever<T, IfTrue, IfFalse> = [T] extends [never] ? IfTrue : IfFalse

const post = async <T extends keyof ApiRequestBody>(
  params: IfNever<
    ApiRequestBody[T],
    { url: T; data?: undefined; config?: AxiosRequestConfig },
    { url: T; data: ApiRequestBody[T]; config?: AxiosRequestConfig }
  >,
): Promise<ApiRequestResponse[T]> => {
  const { url, data, config } = params

  try {
    const response = await axios.post(`${URL}${url}`, data, { withCredentials: true, ...config })

    return response.data
  } catch (error) {
    console.error('Error making POST request:', error)
    throw error
  }
}

const postSSE = <T extends keyof RouteSSEvents>(
  params: { url: T; data?: RouteSSERequests[T]; config?: AxiosRequestConfig },
  onEvent: (data: RouteSSEvents[T]) => void,
  onAPIErrorMessage: (error: unknown) => void,
): EventSource => {
  const { url, data } = params

  // Initiate the SSE connection
  const sse = new EventSource(`${URL}${url}?data=${JSON.stringify(data)}`, {
    withCredentials: true,
  })

  sse.addEventListener('close', () => {
    sse.close()
  })

  sse.onmessage = (event) => {
    const parsedData = JSON.parse(event.data)

    if (Object.hasOwn(parsedData, 'error')) {
      onAPIErrorMessage(parsedData)
    } else {
      onEvent(parsedData)
    }
  }

  sse.onerror = (error) => {
    if (sse.readyState === EventSource.CLOSED) {
      console.log('SSE connection was closed')
    } else {
      console.error('Error with SSE:', error)
      sse.close()
    }
  }

  return sse
}

export const httpService = {
  post,
  postSSE,
}
