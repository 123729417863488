import React, { useState, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Box,
} from '@mui/material'
import ResponsiveSelect from '../../common/custom-material-ui/responsive-select'
import CustomSnackbar from '../../common/custom-snackbar'
import { FeedbackType } from 'common'
import { feedbackService } from './feedback.service'
import ResponsiveTextField from '../../common/custom-material-ui/responsive-text-field'
import { fileService } from '../../services/file.service'
import FilePreview from './file-preview'

const MAX_FILES = 5 // Choose your limit
const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10 MB

interface FeedbackModalProps {
  isOpen: boolean
  isLoggedIn: boolean
  onClose: () => void
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, isLoggedIn, onClose }) => {
  const [feedbackType, setFeedbackType] = useState<FeedbackType>('generalFeedback')
  const [description, setDescription] = useState('')
  const [files, setFiles] = useState<File[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showError, setShowError] = useState(false)

  const fileInputRef = useRef<HTMLInputElement>(null)

  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState<{ main: string; secondary: string }>({
    main: '',
    secondary: '',
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const fileArray = Array.from(event.target.files)
        .slice(0, MAX_FILES) // Limit the number of files
        .filter((file) => file.size <= MAX_FILE_SIZE) // Filter out large files

      setFiles(fileArray)
    }
  }
  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)

      const screenWidth =
        window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      const screenHeight =
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight

      const screenSize = `Width: ${screenWidth}px, Height: ${screenHeight}px`

      // get presigned urls for files
      const presignedUrls = files.length
        ? await fileService.getPresignedUrls(
            files.map((file) => ({
              originalName: file.name,
              mimeType: file.type,
              fileSize: file.size,
            })),
          )
        : []

      const filesWithPresignedUrls = files.map((file, index) => ({
        file,
        presignedUrl: presignedUrls[index].presignedUrl,
      }))

      // upload files to s3
      if (filesWithPresignedUrls.length) {
        await Promise.all(
          filesWithPresignedUrls.map(({ file, presignedUrl }) =>
            fileService.uploadToS3(file, presignedUrl),
          ),
        )
      }

      // create feedback
      await feedbackService.create({
        type: feedbackType,
        description,
        platform: 'web',
        gitCommitHash: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
        screenSize,
        userAgent: navigator.userAgent,
        pageURL: window.location.href,
        fileIds: presignedUrls.map(({ fileId }) => fileId),
      })

      setSnackbarMessage(feedbackResponses[feedbackType])

      // Reset form
      setDescription('')
      setFeedbackType('generalFeedback')
      setFiles([])
      if (fileInputRef.current) {
        fileInputRef.current.value = ''
      }

      onClose()
      setSnackbarOpen(true)
    } catch {
      setShowError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const feedbackResponses: Record<FeedbackType, { main: string; secondary: string }> = {
    generalFeedback: {
      main: 'Feedback submitted!',
      secondary: 'Thanks for sharing your thoughts with us!',
    },
    bugReport: {
      main: 'Bug reported!',
      secondary: `Thanks for helping us improve. We'll look into it!`,
    },
    featureRequest: {
      main: 'Feature Requested!',
      secondary: 'Your input is valuable. It has been sent to our team!',
    },
  }

  return (
    <Box>
      <CustomSnackbar textMain='' show={showError} setShow={setShowError} severity='error' />
      <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth>
        <DialogTitle>Submit Feedback</DialogTitle>
        <DialogContent>
          <ResponsiveSelect
            value={feedbackType}
            onChange={(e) => setFeedbackType(e as FeedbackType)}
            label='Feedback Type'
            sx={{ width: '100%', mt: 2, mb: 3 }}
          >
            <MenuItem value='generalFeedback'>General Feedback</MenuItem>
            <MenuItem value='bugReport'>Bug Report</MenuItem>
            <MenuItem value='featureRequest'>Request a Feature</MenuItem>
          </ResponsiveSelect>

          <ResponsiveTextField
            fullWidth
            multiline
            rows={3}
            label='Description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          {isLoggedIn && (
            <ResponsiveTextField
              inputRef={fileInputRef}
              type='file'
              fullWidth
              onChange={handleFileChange}
              sx={{ mt: 2, mb: 3 }}
              inputProps={{
                multiple: true,
              }}
            />
          )}
          {isLoggedIn && (
            <Box sx={{ mt: 2 }}>
              {files.map((file, index) => (
                <Box sx={{ pb: 3 }} key={index}>
                  <FilePreview file={file} />
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button sx={{ textTransform: 'none' }} onClick={onClose} disabled={isSubmitting}>
            Cancel
          </Button>
          <Button
            sx={{ textTransform: 'none' }}
            color='primary'
            variant='contained'
            onClick={handleSubmit}
            disabled={!description || isSubmitting}
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
        show={isSnackbarOpen}
        setShow={setSnackbarOpen}
        severity='success'
        textMain={snackbarMessage.main}
        textSecondary={snackbarMessage.secondary}
      />
    </Box>
  )
}

export default FeedbackModal
