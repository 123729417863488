import React from 'react'
import ResponsiveAutoComplete from '../common/custom-material-ui/responsive-autocomplete'
import { SxProps } from '@mui/system'
import { KitchenTools as KitchenToolOptions } from 'common'

type KitchenToolProps = {
  sx?: SxProps
  kitchenTools: string[]
  onChange: (value: string[]) => void
}

const KitchenTools: React.FC<KitchenToolProps> = ({ sx, kitchenTools, onChange }) => {
  return (
    <ResponsiveAutoComplete
      sx={sx}
      multiple
      options={KitchenToolOptions}
      value={kitchenTools}
      onChange={(event, newValue) => onChange(newValue)}
      label='Select Your Kitchen Tools'
    />
  )
}

export default KitchenTools
