import React from 'react'
import {
  ListItemIcon,
  ListItemText,
  Drawer,
  List,
  Typography,
  ListItemButton,
  Box,
  Tooltip,
} from '@mui/material'
import { AppMenuItem, adminMenuItems, keyLabels } from './app-menu-item'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'
import { useAuth } from './auth-context'
import whiteLogo from './images/no-container/logo-white-on-transparent-background.png'
import blackLogo from './images/no-container/logo-black-on-transparent-background.png'
import { useUserSubscription } from './contexts/useUserSubscription'

interface SideBarProps {
  open: boolean
  onClose: () => void
  handleMenuItemClick: (item: AppMenuItem) => void
  menuItems: AppMenuItem[]
  isLightMode: boolean
}

const SideBar: React.FC<SideBarProps> = ({
  open,
  onClose,
  handleMenuItemClick,
  menuItems,
  isLightMode,
}) => {
  const location = useLocation() // Get the current URL
  const menuItemLabel = location.pathname.split('/')[1] // Extract the label from the URL

  const { isAdmin } = useAuth()
  const { currentSubscriptionType } = useUserSubscription()

  return (
    <Drawer
      variant='persistent'
      open={open}
      onClose={onClose}
      anchor='left'
      sx={{
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none' /* IE and Edge */,
          scrollbarWidth: 'none' /* Firefox */,
        },
      }}
    >
      <img
        src={isLightMode ? blackLogo : whiteLogo}
        style={{
          width: '65%',
          height: 'auto',
          display: 'block',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '20px',
          marginBottom: '10px',
        }}
        alt='Logo'
      />
      <List sx={{ padding: 1 }}>
        {menuItems.map(({ key, icon, requiresPremium }) => {
          const isDisabled = !isAdmin && requiresPremium && currentSubscriptionType === 'free'
          return (
            <Tooltip
              key={key}
              sx={{ width: '100%' }}
              placement='right'
              title={isDisabled ? <div>Upgrade to use this feature</div> : ''}
            >
              <ListItemButton
                onClick={() => {
                  if (!isDisabled) {
                    handleMenuItemClick({ key, icon })
                  }
                }}
                sx={{
                  pl: 2,
                  mb: 1,
                  borderRadius: '5px',
                  '&:hover': {
                    backgroundColor: menuItemLabel !== key ? 'action.hover' : 'action.selected',
                  },
                  backgroundColor: menuItemLabel === key ? 'action.selected' : 'inherit',
                  opacity: isDisabled ? 0.5 : 1, // gives a disabled look
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={icon} style={{ fontSize: '1.5em' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2'>{keyLabels[key]}</Typography>} />
              </ListItemButton>
            </Tooltip>
          )
        })}

        {isAdmin &&
          adminMenuItems.map(({ key, icon }) => (
            <React.Fragment key={key}>
              <ListItemButton
                onClick={() => handleMenuItemClick({ key, icon })}
                sx={{
                  pl: 2,
                  '&:hover': { backgroundColor: 'action.hover' },
                  backgroundColor: menuItemLabel === key ? 'action.selected' : 'inherit',
                }}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={icon} style={{ fontSize: '1.5em' }} />
                </ListItemIcon>
                <ListItemText primary={<Typography variant='body2'>{keyLabels[key]}</Typography>} />
              </ListItemButton>
            </React.Fragment>
          ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
    </Drawer>
  )
}

export default SideBar
