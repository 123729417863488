import React from 'react'
import { SxProps } from '@mui/system'
import ResponsiveAutoComplete from '../common/custom-material-ui/responsive-autocomplete'
import { Allergies as AllergyOptions } from 'common'

type AllergiesProps = {
  sx?: SxProps
  allergies: string[]
  onChange: (value: string[]) => void
}

const Allergies: React.FC<AllergiesProps> = ({ sx, allergies, onChange }) => {
  return (
    <ResponsiveAutoComplete
      multiple
      sx={sx}
      value={allergies}
      onChange={(event, newValue) => onChange(newValue)}
      options={AllergyOptions}
      label='Select your allergies'
    />
  )
}

export default Allergies
