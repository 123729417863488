import { useMediaQuery } from '@mui/material'

function calculateTop(fontSize: number): number {
  const baseFontSize = 16 // Assuming default browser font size (16px)
  const baseTop = 0 // The default top position in px when the font size is 16px

  // Calculate the new top position based on the fontSize ratio
  const newTop = baseTop - (fontSize - baseFontSize) / 2

  return newTop
}

function useResponsiveFontSize(fontSize: number) {
  const isSmallScreen = useMediaQuery('(max-width: 500px)')
  const isXSmallScreen = useMediaQuery('(max-width: 321px)')

  if (isXSmallScreen) {
    return fontSize * 0.7
  } else if (isSmallScreen) {
    return fontSize * 0.9
  } else {
    return fontSize
  }
}

export const customMaterialUiUtil = {
  calculateTop,
  useResponsiveFontSize,
}
