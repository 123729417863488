import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormGroup,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { authService } from './auth.service'
import { useAuth } from '../auth-context'
import { EmailValidator } from '../validators/email.validator'

type Errors = Record<string, { show: boolean; msg?: string }>

const ResetPassword: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [errors, setErrors] = useState<Errors>({})

  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const { refreshUserStatus } = useAuth()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      EmailValidator.parse({ email })
    } catch (error) {
      return setErrors({
        email: { show: true, msg: `This is not a valid email address` },
      })
    }

    if (newPassword !== confirmNewPassword) {
      return setErrors({
        confirmNewPassword: { show: true, msg: `Does not match password` },
      })
    }

    try {
      setIsLoading(true)

      if (step === 1) {
        await authService.sendPasswordResetCode({ email })
        setStep(2)
      } else if (step === 2) {
        await authService.resetPassword({ email, token, newPassword, confirmNewPassword })
        refreshUserStatus()
      }
    } catch (e) {
      setErrors({
        token: { show: true, msg: 'Invalid token' },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant='h4' align='center'>
        Reset Password
      </Typography>
      <FormControl component='form' onSubmit={handleSubmit} fullWidth>
        <FormGroup>
          {step === 1 && (
            <Box sx={{ pt: 2 }}>
              <ResponsiveTextField
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  if (errors.email?.show) {
                    setErrors({})
                  }
                }}
                error={errors.email?.show}
                helperText={errors.email?.msg}
                label='Email'
                fullWidth
              />
            </Box>
          )}
          {step === 2 && (
            <>
              <Box sx={{ pt: 2 }}>
                <ResponsiveTextField
                  label='Token'
                  value={token}
                  onChange={(e) => {
                    setToken(e.target.value)
                  }}
                  inputProps={{
                    maxLength: 6,
                  }}
                  fullWidth
                  error={errors.token?.show}
                  helperText={errors.token?.msg}
                />
              </Box>
              <Box sx={{ pt: 2 }}>
                <ResponsiveTextField
                  value={newPassword}
                  onChange={(e) => {
                    setNewPassword(e.target.value)
                    if (errors.newPassword?.show) {
                      setErrors({})
                    }
                  }}
                  error={errors.newPassword?.show}
                  helperText={errors.newPassword?.msg}
                  label='New Password'
                  type={showNewPassword ? 'text' : 'password'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge='end'>
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ pt: 2 }}>
                <ResponsiveTextField
                  value={confirmNewPassword}
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value)
                    if (errors.confirmNewPassword?.show) {
                      setErrors({})
                    }
                  }}
                  error={errors.confirmNewPassword?.show}
                  helperText={errors.confirmNewPassword?.msg}
                  label='Confirm Password'
                  type={showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge='end'
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </>
          )}
        </FormGroup>
        <Box>
          <Box display='flex' justifyContent='center' alignItems='center' mt={2}>
            <Button
              variant='contained'
              type='submit'
              disabled={isLoading}
              sx={{ textTransform: 'none' }}
            >
              {step === 2 ? (
                isLoading ? (
                  <>
                    Resetting <CircularProgress size={12} />
                  </>
                ) : (
                  'Reset Password'
                )
              ) : isLoading ? (
                <>
                  Sending <CircularProgress size={12} />
                </>
              ) : (
                'Send Code'
              )}
            </Button>
          </Box>
        </Box>
      </FormControl>
    </Box>
  )
}

export default ResetPassword
