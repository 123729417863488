import React, { useState } from 'react'
import { Box, IconButton, Grid, Stack, SxProps, InputAdornment } from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import ResponsiveTextField from './responsive-text-field'

type ResponsiveNumberInputProps = {
  value: number
  onChange: (value: number) => void
  leadingLabel?: string
  minValue?: number
  maxValue?: number
  unitLabel?: string
  sx?: SxProps
  isDisabled?: boolean
}

const ResponsiveNumberInput: React.FC<ResponsiveNumberInputProps> = ({
  value,
  onChange,
  minValue = 0,
  maxValue = Number.POSITIVE_INFINITY,
  leadingLabel,
  unitLabel,
  sx,
  isDisabled = false,
}) => {
  const [inputValue, setInputValue] = useState<string>(value.toString())

  const generateLabel = () => {
    const labelParts: string[] = []

    // Add leading label if it exists
    if (leadingLabel) {
      labelParts.push(leadingLabel)
    }

    // Add the range or min/max labels
    if (minValue !== 0 && maxValue !== Number.POSITIVE_INFINITY) {
      labelParts.push(`(${minValue} to ${maxValue}${unitLabel ? ` ${unitLabel}` : ''})`)
    } else if (minValue !== 0) {
      labelParts.push(`(At least ${minValue}${unitLabel ? ` ${unitLabel}` : ''})`)
    } else if (maxValue !== Number.POSITIVE_INFINITY) {
      labelParts.push(`(At most ${maxValue}${unitLabel ? ` ${unitLabel}` : ''})`)
    }

    return labelParts.join(' ')
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleBlur = () => {
    let newVal = parseInt(inputValue, 10) || minValue || 0
    if (newVal < minValue) newVal = minValue
    if (newVal > maxValue) newVal = maxValue
    setInputValue(newVal.toString())
    onChange(newVal)
  }

  const incrementValue = () => {
    const currentVal = parseInt(inputValue, 10)
    if (currentVal < (maxValue || Number.POSITIVE_INFINITY)) {
      const incrementedValue = currentVal + 1
      setInputValue(incrementedValue.toString())
      onChange(incrementedValue)
    }
  }

  const decrementValue = () => {
    const currentVal = parseInt(inputValue, 10)
    if (currentVal > (minValue || 0)) {
      const decrementedValue = currentVal - 1
      setInputValue(decrementedValue.toString())
      onChange(decrementedValue)
    }
  }

  return (
    <Box sx={sx}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item xs>
          <ResponsiveTextField
            variant='outlined'
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            label={generateLabel()}
            fullWidth
            disabled={isDisabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Stack alignItems='center' spacing={-1.5}>
                    <IconButton size='small' onClick={incrementValue} disabled={isDisabled}>
                      <ArrowDropUp />
                    </IconButton>
                    <IconButton size='small' onClick={decrementValue} disabled={isDisabled}>
                      <ArrowDropDown />
                    </IconButton>
                  </Stack>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ResponsiveNumberInput
