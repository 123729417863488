import React, { useState } from 'react'
import { Button, Box, Tooltip } from '@mui/material'
import CustomSnackbar from '../common/custom-snackbar'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import { authService } from '../login/auth.service'

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [severity, setSeverity] = useState<'error' | 'success'>('success')
  const [message, setMessage] = useState('')

  const changePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      setMessage('New passwords do not match.')
      setSeverity('error')
      setShowSnackbar(true)
      return
    }
    try {
      await authService.changePassword({ currentPassword, newPassword, confirmNewPassword })

      setMessage('Password has been changed successfully!')
      setSeverity('success')
      setShowSnackbar(true)
    } catch (e) {
      setMessage('Unable to change password')
      setSeverity('error')
      setShowSnackbar(true)
    } finally {
      setCurrentPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
    }
  }

  const handleClose = () => {
    setShowSnackbar(false)
  }

  const passwordsMatch =
    currentPassword.length &&
    newPassword.length &&
    confirmNewPassword.length &&
    newPassword === confirmNewPassword

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: '1rem',
        width: '100%',
      }}
    >
      <ResponsiveTextField
        fullWidth
        type='password'
        label='Current Password'
        value={currentPassword}
        onChange={(e) => setCurrentPassword(e.target.value)}
      />

      <ResponsiveTextField
        fullWidth
        type='password'
        label='New Password'
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />

      <ResponsiveTextField
        fullWidth
        type='password'
        label='Confirm New Password'
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
      />

      <Tooltip title={!passwordsMatch ? 'New passwords must match.' : ''}>
        <span>
          <Button
            variant='contained'
            color='primary'
            onClick={changePassword}
            disabled={!passwordsMatch}
            sx={{ textTransform: 'none' }}
          >
            Change Password
          </Button>
        </span>
      </Tooltip>

      <CustomSnackbar
        show={showSnackbar}
        setShow={handleClose}
        severity={severity}
        textMain={message}
      />
    </Box>
  )
}

export default ChangePassword
