import React, { useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  useTheme,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material'
import { Cancel, CheckCircle, FiberManualRecord } from '@mui/icons-material'
import { userSubscriptionService } from './user-subscription.service'
import { useUserSubscription } from '../contexts/useUserSubscription'
import { useNavigate } from 'react-router-dom'
import { SubscriptionType } from 'common'
import { v4 as uuidv4 } from 'uuid'
import CustomSnackbar from '../common/custom-snackbar'
import { useSidebar } from '../contexts/useSideBar'

const Feature: React.FC<{ included: boolean; name: string }> = ({ included, name }) => (
  <Box display='flex' alignItems='center' marginBottom={1}>
    {included ? <CheckCircle color='primary' /> : <Cancel color='disabled' />}
    <Typography variant='body2' style={{ marginLeft: '0.5rem' }}>
      {name}
    </Typography>
  </Box>
)

const SubscriptionFreeCard = ({
  currentSubscription,
}: {
  currentSubscription: SubscriptionType
}) => {
  const nav = useNavigate()

  return (
    <SubscriptionCard
      subscriptionType='free'
      buttonLabel='Get Started'
      features={[
        '3 recipes a month',
        '3 shopping lists a month',
        'Recipe Book',
        'Simple Chef',
        'Pantry Chef',
        'Family Chef',
      ]}
      onButtonClick={async () => nav('/simple-chef')}
      highlighted={currentSubscription === 'free'}
      endDate={null}
      trialAllowed={false}
    />
  )
}

const SubscriptionPlusCard = ({
  currentSubscription,
  trialAllowed,
  endDate,
  onDowngrade,
  onSubscribe,
  onManageSubscription,
}: {
  currentSubscription: SubscriptionType
  trialAllowed: boolean
  endDate: Date | null
  onDowngrade: () => Promise<void>
  onSubscribe: () => Promise<void>
  onManageSubscription: () => Promise<void>
}) => {
  let buttonLabel = 'Subscribe'

  if (currentSubscription === 'pro') {
    buttonLabel = 'Downgrade to Plus'
  }

  if (currentSubscription === 'free' && trialAllowed) {
    buttonLabel = 'Start Trial'
  }

  if (currentSubscription === 'plus' && !trialAllowed) {
    buttonLabel = 'Manage Subscription'
  }

  const onButtonClick = async () => {
    if (currentSubscription === 'pro') {
      await onDowngrade()
    } else if (currentSubscription === 'plus') {
      await onManageSubscription()
    } else {
      await onSubscribe()
    }
  }

  return (
    <SubscriptionCard
      subscriptionType='plus'
      buttonLabel={buttonLabel}
      features={[
        'Everything in Free',
        '40 recipes a month',
        '40 shopping lists a month',
        'Surprise Chef',
        'Health Chef',
        'Global Chef',
        'Festive Chef',
        'Fusion Frenzy',
        'Five Ingredient Fix',
        'Batch Bliss',
        'Skill Sharpening',
        'Priority access to new features',
      ]}
      onButtonClick={onButtonClick}
      highlighted={currentSubscription === 'plus'}
      endDate={endDate}
      trialAllowed={trialAllowed}
    />
  )
}

const SubscriptionProCard = ({
  currentSubscription,
  endDate,
  onSubscribe,
  onUpgrade,
  onManageSubscription,
}: {
  currentSubscription: SubscriptionType
  endDate: Date | null
  onSubscribe: () => Promise<void>
  onUpgrade: () => Promise<void>
  onManageSubscription: () => Promise<void>
}) => {
  let buttonLabel = 'Subscribe'

  if (currentSubscription === 'plus') {
    buttonLabel = 'Upgrade to Pro'
  }

  if (currentSubscription === 'pro') {
    buttonLabel = 'Manage Subscription'
  }

  const onButtonClick = async () => {
    if (currentSubscription === 'pro') {
      await onManageSubscription()
    } else if (currentSubscription === 'plus') {
      await onUpgrade()
    } else {
      await onSubscribe()
    }
  }

  return (
    <SubscriptionCard
      subscriptionType='pro'
      buttonLabel={buttonLabel}
      features={['Everything in Plus', '100 recipes a month', '100 shopping lists a month']}
      onButtonClick={onButtonClick}
      highlighted={currentSubscription === 'pro'}
      endDate={endDate}
      trialAllowed={false}
    />
  )
}

const SubscriptionCard: React.FC<{
  subscriptionType: SubscriptionType
  buttonLabel: string
  onButtonClick: () => Promise<void>
  highlighted: boolean
  endDate: Date | null
  trialAllowed: boolean
  features: string[]
}> = ({
  subscriptionType,
  buttonLabel,
  onButtonClick,
  highlighted,
  endDate,
  trialAllowed,
  features,
}) => {
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(false)

  const isExpired = endDate && endDate < new Date()

  return (
    <Card
      style={{
        margin: '1rem',
        boxShadow: highlighted ? '0px 0px 10px #3f51b5' : 'none',
        borderRadius: '15px',
        padding: '1rem',
        backgroundColor: theme.palette.mode === 'light' ? '#f5f5f5' : undefined,
      }}
    >
      <CardContent>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Typography variant='h5' gutterBottom style={{ textTransform: 'capitalize' }}>
              {subscriptionType}
            </Typography>
            {subscriptionType === 'plus' && trialAllowed && (
              <Typography variant='body2' color='textSecondary' style={{ marginLeft: '0.5rem' }}>
                7 day free trial
              </Typography>
            )}
          </Box>
          {highlighted && <CheckCircle color='primary' />}
        </Box>
        {highlighted && subscriptionType === 'pro' && (
          <Typography variant='body1' color='primary'>
            Thanks for being a Pro customer
          </Typography>
        )}
        {highlighted && subscriptionType === 'plus' && (
          <Typography variant='body1' color='primary'>
            Thanks for being a Plus customer
          </Typography>
        )}
        <Typography variant='h6' gutterBottom>
          ${subscriptionType === 'free' ? '0.00' : subscriptionType === 'plus' ? '9.99' : '19.99'} /
          month
        </Typography>

        {features.map((feature, i) => (
          <Feature key={i} name={feature} included={true} />
        ))}
        {endDate && highlighted && (
          <Typography sx={{ mt: '2rem' }} variant='body2' color={'error'}>
            Membership {isExpired ? 'expired' : 'expires'} on {endDate.toLocaleDateString()}
          </Typography>
        )}
        <Button
          variant='contained'
          color='primary'
          onClick={async () => {
            try {
              setIsLoading(true)
              await onButtonClick()
            } finally {
              setTimeout(() => setIsLoading(false), 1000)
            }
          }}
          style={{ textTransform: 'none', marginTop: '2rem', width: '100%' }}
        >
          {isLoading && subscriptionType !== 'free' ? (
            <>
              <span style={{ paddingRight: 2 }}>Loading</span>{' '}
              <CircularProgress color='inherit' size={20} />
            </>
          ) : (
            buttonLabel
          )}
        </Button>
      </CardContent>
    </Card>
  )
}

const ConfirmationModal: React.FC<{
  open: boolean
  handleClose: () => void
  handleSuccess: () => Promise<void>
  action: 'upgrade' | 'downgrade'
}> = ({ open, handleClose, handleSuccess, action }) => {
  const [isChecked, setChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { isSidebarOpen } = useSidebar()

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  const handleConfirmClick = async () => {
    try {
      setIsLoading(true)
      await handleSuccess()
    } finally {
      setIsLoading(false)
      setChecked(false)
    }
  }

  type BulletListProps = {
    items: string[]
  }

  const BulletList: React.FC<BulletListProps> = ({ items }) => {
    return (
      <List>
        {items.map((item, index) => (
          <ListItem key={index} dense>
            <ListItemIcon>
              <FiberManualRecord fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        try {
          handleClose()
        } finally {
          setIsLoading(false)
          setChecked(false)
        }
      }}
      sx={{ left: isSidebarOpen ? 240 : 0 }}
    >
      <DialogTitle>
        <Typography variant='h5'>
          {action.charAt(0).toUpperCase() + action.slice(1)} Subscription
        </Typography>
      </DialogTitle>
      <DialogContent>
        {action === 'downgrade' ? (
          <>
            <DialogContentText>
              We understand that sometimes, adjustments need to be made. AI Chef Plus is a fantastic
              tool in your culinary experience, but please be aware of the following:
            </DialogContentText>
            <BulletList
              items={[
                'The downgrade will take effect immediately.',
                'To upgrade to AI Chef Pro in the future there is a $10 AUD Upgrade Fee.',
              ]}
            />
          </>
        ) : (
          <>
            <DialogContentText>
              {`Congratulations on deciding to upgrade! By doing so, you're unlocking the full
              culinary experience of AI Chef.`}
            </DialogContentText>
            <BulletList
              items={[
                'The upgrade will be effective immediately.',
                'There will be an Upgrade Fee of $10 AUD.',
                'Your taste buds are in for a treat!',
              ]}
            />
          </>
        )}
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              name='confirmationCheckbox'
              color='primary'
            />
          }
          label={`I understand and wish to ${action}`}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            try {
              handleClose()
            } finally {
              setIsLoading(false)
              setChecked(false)
            }
          }}
          color='primary'
          style={{ textTransform: 'none' }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirmClick()
          }}
          color='primary'
          autoFocus
          disabled={!isChecked}
          style={{ textTransform: 'none' }}
        >
          {isLoading ? (
            <>
              <span style={{ paddingRight: 2 }}>
                {action === 'upgrade' ? 'Upgrading' : 'Downgrading'}
              </span>
              <CircularProgress color='inherit' size={20} />
            </>
          ) : (
            `Confirm`
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SubscriptionPage: React.FC = () => {
  const {
    currentSubscriptionType,
    currentEndDate,
    trialAllowed,
    isLoading,
    checkAndUpdateSubscription,
  } = useUserSubscription()

  const [modalOpen, setModalOpen] = useState(false)
  const [actionType, setActionType] = useState<'upgrade' | 'downgrade'>('upgrade')
  const [idempotencyKeys, setIdempotencyKeys] = useState<string[]>([])
  const [showError, setShowError] = useState(false)
  const [isCheckingSubscription, setIsCheckingSubscription] = useState(false)

  const goToBillingPortal = async () => {
    const url = await userSubscriptionService.getBillingPortalUrl()
    window.open(url, '_self')
  }

  const goToSubscriptionPage = async (subscriptionType: 'plus' | 'pro') => {
    const url = await userSubscriptionService.getCheckoutUrl({ subscriptionType })
    window.open(url, '_self')
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const onUpgradeDowngrade = async () => {
    const _idempotencyKeys = idempotencyKeys

    if (!_idempotencyKeys.length || _idempotencyKeys.length !== 5) {
      _idempotencyKeys[0] = uuidv4()
      _idempotencyKeys[1] = uuidv4()
      _idempotencyKeys[2] = uuidv4()
      _idempotencyKeys[3] = uuidv4()
      _idempotencyKeys[4] = uuidv4()

      // Generate a new idempotency key if it's not already set
      setIdempotencyKeys(_idempotencyKeys)
    }

    try {
      if (!_idempotencyKeys) {
        return
      }

      if (actionType === 'upgrade') {
        await userSubscriptionService.upgradeSubscription({
          idempotencyKeys: [
            _idempotencyKeys[0],
            _idempotencyKeys[1],
            _idempotencyKeys[2],
            _idempotencyKeys[3],
          ],
        })
      } else if (actionType === 'downgrade') {
        await userSubscriptionService.downgradeSubscription({ idempotencyKey: _idempotencyKeys[4] })
      }

      checkAndUpdateSubscription()

      setIdempotencyKeys([]) // Reset the key after a successful operation
      setModalOpen(false)
    } catch (error) {
      setShowError(true) // Show the error snackbar when there's an error
    }
  }

  return isLoading ? (
    <CircularProgress />
  ) : (
    <React.Fragment>
      <CustomSnackbar show={showError} setShow={setShowError} severity='error' />
      <Grid container justifyContent='center' alignItems='center' direction='column' spacing={1}>
        <Grid container item xs={12} justifyContent='center' spacing={1}>
          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionFreeCard currentSubscription={currentSubscriptionType} />
          </Grid>

          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionPlusCard
              currentSubscription={currentSubscriptionType}
              trialAllowed={trialAllowed}
              endDate={currentEndDate}
              onManageSubscription={goToBillingPortal}
              onSubscribe={() => goToSubscriptionPage('plus')}
              onDowngrade={async () => {
                setActionType('downgrade')
                setModalOpen(true)
              }}
            />
          </Grid>

          <Grid item xs={12} sm={8} md={4}>
            <SubscriptionProCard
              currentSubscription={currentSubscriptionType}
              endDate={currentEndDate}
              onManageSubscription={goToBillingPortal}
              onSubscribe={() => goToSubscriptionPage('pro')}
              onUpgrade={async () => {
                setActionType('upgrade')
                setModalOpen(true)
              }}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 10 }}>
          Is your subscription not correct?
          <Button
            sx={{ textTransform: 'none' }}
            onClick={async () => {
              try {
                setIsCheckingSubscription(true)
                await checkAndUpdateSubscription()
              } finally {
                setIsCheckingSubscription(false)
              }
            }}
          >
            {isCheckingSubscription ? 'Checking' : 'Click here to check it'}
          </Button>
        </Box>
      </Grid>
      <ConfirmationModal
        open={modalOpen}
        handleClose={closeModal}
        handleSuccess={onUpgradeDowngrade}
        action={actionType}
      />
    </React.Fragment>
  )
}

export default SubscriptionPage
