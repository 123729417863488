import React, { useState } from 'react'
import { Autocomplete, AutocompleteProps } from '@mui/material'
import ResponsiveTextField from './responsive-text-field'

type ResponsiveAutoCompleteProps = Omit<
  AutocompleteProps<any, any, any, any, any>,
  'renderInput'
> & {
  labelFontSizeWhenAbove?: number
  labelFontSizeWhenInMiddle?: number
  label: string
}

const ResponsiveAutoComplete: React.FC<ResponsiveAutoCompleteProps> = ({
  labelFontSizeWhenAbove = 23,
  labelFontSizeWhenInMiddle = 18,
  label,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  const multipleProps = props.multiple
    ? {
        open,
        onOpen: () => {
          setOpen(true)
        },
        onClose: () => {
          return
        },
        onBlur: () => {
          // close the dropdown when the user clicks outside of it
          setOpen(false)
        },
      }
    : {}

  return (
    <Autocomplete
      {...props}
      {...multipleProps}
      sx={{ ...props.sx }}
      renderInput={(params) => (
        <ResponsiveTextField
          {...params}
          labelFontSizeWhenAbove={labelFontSizeWhenAbove}
          labelFontSizeWhenInMiddle={labelFontSizeWhenInMiddle}
          label={label}
          value={props.value}
        />
      )}
    />
  )
}

export default ResponsiveAutoComplete
