import React, { useContext, useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import LoginSignUpPage from './login/login-sign-up-page'
import TopBar from './top-bar/top-bar'
import { AuthContext } from './auth-context'
import MyRecipes from './my-recipes/my-recipes'
import SimpleChef from './simple-chef/simple-chef'
import PantryChef from './pantry-chef/pantry-chef'
import SurpriseChef from './surprise-chef/surprise-chef'
import HealthChef from './health-chef/health-chef'
import GlobalChef from './global-chef/global-chef'
import FamilyChef from './family-chef/family-chef'
import FestiveChef from './festive-chef/festive-chef'
import FusionFrenzy from './fusion-frenzy/fusion-frenzy'
import FiveIngredientFix from './five-ingredient-fix/five-ingredient-fix'
import BatchBliss from './batch-bliss/batch-bliss'
import SkillSharpening from './skill-sharpening/skill-sharpening'
import { Box, useTheme } from '@mui/material'
import SideBar from './side-bar'
import { AppMenuItem, menuItems } from './app-menu-item'
import ViewRecipe from './my-recipes/view-recipe'
import { useSidebar } from './contexts/useSideBar'
import ProfilePage from './profile/profile-page'
import FeedbackButton from './admin/feedback/feedback-button'
import FeedbackModal from './admin/feedback/feedback-modal'
import AdminDashboard from './admin/admin-dashboard'
import FeedbackList from './admin/feedback/feedback-list'
import VerifyEmail from './login/verify-email'
import { UserSubscriptionContext } from './contexts/useUserSubscription'

function App({
  isLightMode,
  handleDarkLightModeChange,
}: {
  isLightMode: boolean
  handleDarkLightModeChange: () => void
}) {
  const { isLoggedIn, isEmailVerified, isAdmin } = useContext(AuthContext)
  const { isLargeScreen, isSidebarOpen, setIsSidebarOpen } = useSidebar()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const theme = useTheme()

  const navigate = useNavigate()

  const handleMenuItemClick = (item: AppMenuItem) => {
    navigate(item.key)
    if (!isLargeScreen) {
      setIsSidebarOpen(false)
    }
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {isLoggedIn && isEmailVerified && (
          <SideBar
            open={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            handleMenuItemClick={handleMenuItemClick}
            menuItems={menuItems}
            isLightMode={isLightMode}
          />
        )}
        <Box
          component='main'
          sx={{
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: isLoggedIn && isEmailVerified && isSidebarOpen ? '240px' : 0,
          }}
        >
          {isLoggedIn && isEmailVerified && (
            <TopBar
              isLightMode={isLightMode}
              handleDarkLightModeChange={handleDarkLightModeChange}
            />
          )}

          <Routes>
            <Route
              path='/login'
              element={
                <PublicRoute>
                  <LoginSignUpPage
                    isLightMode={isLightMode}
                    handleDarkLightModeChange={handleDarkLightModeChange}
                  />
                </PublicRoute>
              }
            />

            <Route
              path='/verify-email'
              element={
                <VerifyEmailRoute>
                  <VerifyEmail
                    isLightMode={isLightMode}
                    handleDarkLightModeChange={handleDarkLightModeChange}
                  />
                </VerifyEmailRoute>
              }
            />

            <Route
              path='/my-recipes'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <MyRecipes />
                </PrivateRoute>
              }
            />

            <Route
              path='/my-recipes/:id'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <ViewRecipe />
                </PrivateRoute>
              }
            />

            <Route
              path='/simple-chef'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <SimpleChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/pantry-chef'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <PantryChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/family-chef'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <FamilyChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/surprise-chef'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <SurpriseChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/health-chef'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <HealthChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/global-chef'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <GlobalChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/festive-chef'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <FestiveChef />
                </PrivateRoute>
              }
            />

            <Route
              path='/fusion-frenzy'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <FusionFrenzy />
                </PrivateRoute>
              }
            />

            <Route
              path='/five-ingredient-fix'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <FiveIngredientFix />
                </PrivateRoute>
              }
            />

            <Route
              path='/batch-bliss'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <BatchBliss />
                </PrivateRoute>
              }
            />

            <Route
              path='/skill-sharpening'
              element={
                <PrivateRoute isAdmin={isAdmin} premiumRoute={true}>
                  <SkillSharpening />
                </PrivateRoute>
              }
            />

            <Route
              path='/profile/:section'
              element={
                <PrivateRoute isAdmin={isAdmin}>
                  <ProfilePage />
                </PrivateRoute>
              }
            />

            <Route
              path='/admin'
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            />

            <Route
              path='/admin/feedback'
              element={
                <AdminRoute>
                  <FeedbackList />
                </AdminRoute>
              }
            />

            <Route path='*' element={<CatchAllRoute />} />
          </Routes>
        </Box>
        <FeedbackButton onClick={() => setIsModalOpen(true)} />
        <FeedbackModal
          isOpen={isModalOpen}
          isLoggedIn={isLoggedIn}
          onClose={() => setIsModalOpen(false)}
        />
      </div>
    </React.Fragment>
  )
}

const PublicRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoggedIn, isEmailVerified } = useContext(AuthContext)

  if (isLoggedIn && isEmailVerified) {
    return <Navigate to='/simple-chef' replace />
  } else if (isLoggedIn && !isEmailVerified) {
    return <Navigate to='/verify-email' replace />
  }

  return <React.Fragment>{children}</React.Fragment>
}

const VerifyEmailRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isLoggedIn, isEmailVerified } = useContext(AuthContext)

  if (!isLoggedIn) {
    return <Navigate to='/login' replace />
  }

  if (isEmailVerified) {
    return <Navigate to='/simple-chef' replace />
  }

  return <React.Fragment>{children}</React.Fragment>
}

const PrivateRoute: React.FC<{
  children: React.ReactNode
  premiumRoute?: boolean
  isAdmin: boolean
}> = ({ children, premiumRoute = false, isAdmin = false }) => {
  const { isLoggedIn, isEmailVerified } = useContext(AuthContext)
  const { currentSubscriptionType } = useContext(UserSubscriptionContext)

  if (!isLoggedIn) {
    return <Navigate to='/login' replace />
  }

  if (!isEmailVerified) {
    return <Navigate to='/verify-email' replace />
  }

  if (premiumRoute && !isAdmin && currentSubscriptionType === 'free') {
    return <Navigate to='/simple-chef' replace />
  }

  return <React.Fragment>{children}</React.Fragment>
}

const AdminRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isAdmin } = useContext(AuthContext)
  return isAdmin ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <Navigate to='/simple-chef' replace />
  )
}

const CatchAllRoute = () => {
  return <Navigate to='/simple-chef' replace />
}

export default App
