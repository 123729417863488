import React from 'react'
import { Typography } from '@mui/material'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'

interface ProfileInformationProps {
  name: string
  email: string
  onChange: (name: string) => void
}

const ProfileInformation: React.FC<ProfileInformationProps> = ({ name, email, onChange }) => {
  return (
    <div>
      <Typography variant='body1' sx={{ mb: 4 }}>
        <b>Email:</b> {email}
      </Typography>
      <div>
        <ResponsiveTextField label='Name' value={name} onChange={(e) => onChange(e.target.value)} />
      </div>
    </div>
  )
}

export default ProfileInformation
