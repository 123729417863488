import React, { useCallback, useEffect } from 'react'
import { Box, Paper, Tab, Tabs, Typography, CircularProgress } from '@mui/material'
import { useSidebar } from '../contexts/useSideBar'
import KitchenTools from './kitchen-tools'
import SubscriptionPage from './subscription'
import DietaryRequirements from './dietary-preferences'
import { userProfileService } from './user-profile.service'
import Allergies from './allergy-intolerance'
import SkillLevelSelect from './skill-level'
import ChangePassword from './change-password'
import { UserPreference } from 'common'
import ProfileInformation from './profile-information'
import useUser from './useUser.hook'
import useUserPreference from './useUserPreference.hook'
import { debounce } from 'lodash'
import { userService } from './user.service'
import MaxMinutesPerMeal from '../common/components/max-minutes-per-meal'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  Fastfood,
  Kitchen,
  LocalHospital,
  Payment,
  Settings,
  Star,
  Timer,
  VpnKey,
} from '@mui/icons-material'

const TabPanel: React.FC<{ children: React.ReactNode; index: number; value: number }> = ({
  children,
  index,
  value,
}) => {
  return value === index ? <Box p={2}>{children}</Box> : null
}

const ProfilePage = () => {
  const [tab, setTab] = React.useState<number>(0)
  const { userPreference, setUserPreference } = useUserPreference()
  const { user, setUser } = useUser()

  const { isSidebarOpen } = useSidebar()

  const location = useLocation()
  const navigate = useNavigate()

  // Set initial tab based on URL
  useEffect(() => {
    const pathname = location.pathname.split('/').pop()

    if (!pathname) {
      return
    }

    const tabMap: Record<string, number> = {
      account: 0,
      password: 1,
      subscription: 2,
      kitchenTools: 3,
      dietaryPreferences: 4,
      allergies: 5,
      skillLevel: 6,
      cookingTime: 7,
    }

    const tab = tabMap[pathname]

    setTab(tab || 0)
  }, [location.pathname])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue)

    const tabToPathnameMap: Record<number, string> = {
      0: 'account',
      1: 'password',
      2: 'subscription',
      3: 'kitchenTools',
      4: 'dietaryPreferences',
      5: 'allergies',
      6: 'skillLevel',
      7: 'cookingTime',
    }

    navigate(`/profile/${tabToPathnameMap[newValue]}`)
  }

  const handleUpdateUserPreference = async (userPreference: Partial<UserPreference>) => {
    const userProfile = await userProfileService.update({ userPreference })
    setUserPreference(userProfile)
  }

  const debouncedApiCall = useCallback(
    debounce(async (name: string) => {
      try {
        await userService.update({ name })
      } catch (error) {
        console.error('Error updating user:', error)
      }
    }, 500),
    [],
  )

  const handleUpdateUser = (name: string) => {
    if (!user) {
      return
    }
    setUser({ ...user, name })
    debouncedApiCall(name)
  }

  const Label = ({
    icon: Icon,
    label,
  }: {
    icon: React.ComponentType<{ sx?: React.CSSProperties }>
    label: string
  }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Icon sx={{ marginRight: 0.5 }} />
        {label}
      </Box>
    )
  }

  return (
    <Box sx={{ p: 2, width: isSidebarOpen ? 'calc(100vw - 240px)' : '100vw' }}>
      <Paper elevation={2}>
        {!userPreference || !user ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '10vh',
            }}
          >
            <CircularProgress size={20} color='inherit' style={{ alignSelf: 'center' }} />
          </Box>
        ) : (
          <Box>
            <Tabs
              value={tab}
              onChange={handleTabChange}
              variant='scrollable'
              scrollButtons={true}
              allowScrollButtonsMobile={true}
            >
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Account' icon={Settings} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Password' icon={VpnKey} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Subscription' icon={Payment} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Kitchen Tools' icon={Kitchen} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Dietary Preferences' icon={Fastfood} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Allergies' icon={LocalHospital} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Skill Level' icon={Star} />}
              />
              <Tab
                sx={{ textTransform: 'none' }}
                label={<Label label='Cooking Time' icon={Timer} />}
              />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Account
              </Typography>
              <ProfileInformation
                name={user.name}
                email={user.email}
                onChange={(val) => {
                  handleUpdateUser(val)
                }}
              />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Password
              </Typography>
              <ChangePassword />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Subscription
              </Typography>
              <SubscriptionPage />
            </TabPanel>
            <TabPanel value={tab} index={3}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Kitchen Tools
              </Typography>
              <KitchenTools
                kitchenTools={userPreference?.kitchenTools || []}
                onChange={(val) => handleUpdateUserPreference({ kitchenTools: val })}
              />
            </TabPanel>
            <TabPanel value={tab} index={4}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Dietary Preferences
              </Typography>
              <DietaryRequirements
                dietaryRequirements={userPreference?.dietaryRequirements || []}
                onChange={(val) => handleUpdateUserPreference({ dietaryRequirements: val })}
              />
            </TabPanel>
            <TabPanel value={tab} index={5}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Allergies
              </Typography>
              <Allergies
                allergies={userPreference?.allergies || []}
                onChange={(val) => handleUpdateUserPreference({ allergies: val })}
              />
            </TabPanel>
            <TabPanel value={tab} index={6}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Skill level
              </Typography>
              <SkillLevelSelect
                skillLevel={userPreference?.skillLevel || 'beginner'}
                onChange={(val) => handleUpdateUserPreference({ skillLevel: val })}
              />
            </TabPanel>
            <TabPanel value={tab} index={7}>
              <Typography variant='h6' sx={{ mb: 2 }}>
                Cooking time per meal
              </Typography>
              <MaxMinutesPerMeal
                maxMinutes={userPreference.maxMinutesPerMeal}
                onChange={(val) => handleUpdateUserPreference({ maxMinutesPerMeal: val })}
              />
            </TabPanel>
          </Box>
        )}
      </Paper>
    </Box>
  )
}

export default ProfilePage
