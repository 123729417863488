import { SxProps } from '@mui/material'
import React from 'react'
import ResponsiveAutoComplete from '../common/custom-material-ui/responsive-autocomplete'
import { DietaryRequirements as DietaryRequirementOptions } from 'common'

type DietaryRequirementsProps = {
  sx?: SxProps
  dietaryRequirements: string[]
  onChange: (newValue: string[]) => void
}

const DietaryRequirements: React.FC<DietaryRequirementsProps> = ({
  sx,
  dietaryRequirements,
  onChange,
}) => {
  return (
    <ResponsiveAutoComplete
      sx={sx}
      multiple={true}
      value={dietaryRequirements}
      onChange={(e, newValue) => newValue && onChange(newValue)}
      options={DietaryRequirementOptions}
      label='Select your dietary requirements'
    />
  )
}

export default DietaryRequirements
