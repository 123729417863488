import { SubscriptionType } from 'common'
import { httpService } from '../services/http.service'

const getCheckoutUrl = async ({ subscriptionType }: { subscriptionType: SubscriptionType }) => {
  const { url } = await httpService.post({
    url: '/create-subscription-url',
    data: { subscriptionType },
  })

  return url
}

const getBillingPortalUrl = async () => {
  const { url } = await httpService.post({
    url: '/create-billing-portal-url',
  })

  return url
}

const getUserSubscription = async () => {
  const userSubscription = await httpService.post({
    url: '/get-user-subscription',
  })

  return userSubscription
}

const checkAndUpdateUserSubscription = async () => {
  const userSubscription = await httpService.post({
    url: '/check-and-update-user-subscription',
  })

  return userSubscription
}

const upgradeSubscription = async ({
  idempotencyKeys,
}: {
  idempotencyKeys: [string, string, string, string]
}) => {
  await httpService.post({
    url: '/upgrade-subscription',
    data: { idempotencyKeys },
  })
}

const downgradeSubscription = async ({ idempotencyKey }: { idempotencyKey: string }) => {
  await httpService.post({
    url: '/downgrade-subscription',
    data: { idempotencyKey },
  })
}

export const userSubscriptionService = {
  getCheckoutUrl,
  getBillingPortalUrl,
  getUserSubscription,
  checkAndUpdateUserSubscription,
  upgradeSubscription,
  downgradeSubscription,
}
