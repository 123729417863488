import React from 'react'
import { SxProps } from '@mui/material'
import ResponsiveAutoComplete from '../custom-material-ui/responsive-autocomplete'

const techniques = [
  'Knife skills',
  'Sautéing',
  'Braising',
  'Roasting',
  'Grilling',
  'Baking',
  'Broiling',
  'Poaching',
  'Steaming',
  'Simmering',
  'Blanching',
  'Pan-frying',
  'Stir-frying',
  'Deep-frying',
  'Sous vide',
  'Emulsifying',
  'Whisking',
  'Caramelizing',
  'Tempering',
  'Flambé',
]

type TechniqueToLearnProps = {
  sx?: SxProps
  technique: string
  onChange: (value: string) => void
}

const TechniqueToLearn: React.FC<TechniqueToLearnProps> = ({ sx, technique, onChange }) => {
  return (
    <ResponsiveAutoComplete
      sx={sx}
      value={technique}
      onChange={(event, newValue) => newValue && onChange(newValue)}
      options={techniques}
      label='Technique to learn'
    />
  )
}

export default TechniqueToLearn
