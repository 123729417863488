import React, { useEffect, useState } from 'react'
import {
  List,
  ListItem,
  Card,
  Typography,
  Box,
  Divider,
  Button,
  Collapse,
  Select,
  MenuItem,
} from '@mui/material'
import { feedbackService } from './feedback.service'
import { Comment, FeedbackStatus, FeedbackType, FeedbackWithCommentsAndFiles } from 'common'
import moment from 'moment'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import FeedbackComment from './feedback-comment'
import ViewRichText from '../../common/view-rich-text'
import AddComment from './add-comment'
import FeedbackStatusDropdown from './feedback-status-dropdown'
import FeedbackMetaInfo from './feedback-meta-info'
import FeedbackActions from './feedback-actions'
import ViewFile from './view-file'

const PAGE_SIZE = 15 // Number of items per page

// Function to map feedback type to a more readable string
const formatFeedbackType = (type: FeedbackType) => {
  switch (type) {
    case 'bugReport':
      return 'Bug Report'
    case 'featureRequest':
      return 'Feature Request'
    case 'generalFeedback':
      return 'General Feedback'
    default:
      return type
  }
}

const FeedbackList = () => {
  const [feedbacks, setFeedbacks] = useState<FeedbackWithCommentsAndFiles[]>([])
  const [openComments, setOpenComments] = useState<string | null>(null)
  const [openFiles, setOpenFiles] = useState<string | null>(null)
  const [statusFilter, setStatusFilter] = useState<FeedbackStatus | 'all'>('all')
  const [page, setPage] = useState(1)

  useEffect(() => {
    const loadFeedback = async () => {
      const feedbacks = await feedbackService.get({
        page,
        pageSize: PAGE_SIZE,
        status: statusFilter === 'all' ? undefined : statusFilter,
      })
      setFeedbacks(feedbacks)
    }

    loadFeedback()
  }, [statusFilter, page])

  const timeAgo = (date: Date) => {
    return moment(date).fromNow()
  }

  const handleStatusChange = async (feedbackId: string, newStatus: FeedbackStatus) => {
    // Implement logic to update the feedback status in your backend.
    // For now, let's just update it locally:
    await feedbackService.updateFeedbackStatus({ feedbackId, status: newStatus })

    const updatedFeedbacks = feedbacks.map((feedback) =>
      feedback.id === feedbackId ? { ...feedback, status: newStatus } : feedback,
    )

    setFeedbacks(updatedFeedbacks)
  }

  const addComment = async (feedbackId: string, content: string) => {
    const { comment: newComment } = await feedbackService.addComment({
      feedbackId,
      content,
    })

    const updatedFeedbacks: FeedbackWithCommentsAndFiles[] = feedbacks.map((feedback) => {
      if (feedback.id === feedbackId) {
        return { ...feedback, comments: [...feedback.comments, newComment] }
      }

      return feedback
    })

    setFeedbacks(updatedFeedbacks)
  }

  const editComment = async (commentId: string, content: string) => {
    const { comment: updatedComment } = await feedbackService.editComment({
      commentId,
      content,
    })

    const updatedFeedbacks: FeedbackWithCommentsAndFiles[] = feedbacks.map((feedback) => {
      const updatedComments = feedback.comments.map((comment) => {
        if (comment.id === commentId) {
          return updatedComment
        }

        return comment
      })

      return { ...feedback, comments: updatedComments }
    })

    setFeedbacks(updatedFeedbacks)
  }

  const deleteComment = async (commentId: string) => {
    await feedbackService.deleteComment({ commentId })

    const updatedFeedbacks: FeedbackWithCommentsAndFiles[] = feedbacks.map((feedback) => {
      const updatedComments = feedback.comments.filter((comment) => comment.id !== commentId)

      return { ...feedback, comments: updatedComments }
    })

    setFeedbacks(updatedFeedbacks)
  }

  return (
    <Box>
      <Box mt={2} mb={2} display='flex' justifyContent='center' alignItems='center'>
        <Select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value as FeedbackStatus | 'all')}
        >
          <MenuItem value='all'>All</MenuItem>
          <MenuItem value='pending'>Pending</MenuItem>
          <MenuItem value='reviewed'>Reviewed</MenuItem>
          <MenuItem value='inProgress'>In Progress</MenuItem>
          <MenuItem value='resolved'>Resolved</MenuItem>
        </Select>
      </Box>

      <List component='nav'>
        {feedbacks.map((feedback) => (
          <ListItem key={feedback.id}>
            <Card style={{ width: '100%', marginBottom: '10px', padding: '1em' }}>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <FeedbackStatusDropdown
                  feedbackId={feedback.id}
                  currentStatus={feedback.status}
                  onChange={handleStatusChange}
                />
                <Typography variant='body2'>{timeAgo(feedback.createdAt)}</Typography>
              </Box>
              <Divider variant='middle' style={{ margin: '10px 0' }} />
              <Typography variant='h6' marginBottom='0.5em'>
                {formatFeedbackType(feedback.type)}
              </Typography>
              <Typography>
                <ViewRichText text={feedback.description} />
              </Typography>
              <Divider variant='middle' style={{ margin: '10px 0' }} />
              <FeedbackMetaInfo feedback={feedback} />

              <FeedbackActions
                feedback={feedback}
                onToggleComments={() => {
                  openComments === feedback.id
                    ? setOpenComments(null)
                    : setOpenComments(feedback.id)
                }}
                onToggleFiles={() => {
                  openFiles === feedback.id ? setOpenFiles(null) : setOpenFiles(feedback.id)
                }}
              />

              {/* Display Files */}
              <Collapse in={openFiles === feedback.id} timeout='auto' unmountOnExit>
                {feedback.files.map((file) => (
                  <Box key={file.id}>
                    <ViewFile file={file} />
                    <Divider />
                  </Box>
                ))}
              </Collapse>

              <Collapse in={openComments === feedback.id} timeout='auto' unmountOnExit>
                {feedback.comments.map((comment: Comment) => (
                  <Box key={comment.id}>
                    <FeedbackComment
                      comment={comment}
                      onEdit={editComment}
                      onDelete={deleteComment}
                    />
                    <Divider />
                  </Box>
                ))}
                <AddComment feedbackId={feedback.id} onAddComment={addComment} />
              </Collapse>
            </Card>
          </ListItem>
        ))}
      </List>

      {/* Pagination Controls */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Button
          sx={{ textTransform: 'none' }}
          disabled={page === 1}
          onClick={() => setPage((prevPage) => prevPage - 1)}
        >
          <NavigateBefore /> Previous
        </Button>
        <Button
          sx={{ textTransform: 'none' }}
          disabled={feedbacks.length < PAGE_SIZE}
          onClick={() => setPage((prevPage) => prevPage + 1)}
        >
          Next <NavigateNext />
        </Button>
      </Box>
    </Box>
  )
}

export default FeedbackList
