import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Container, Paper, Typography } from '@mui/material'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'

const RecipeNotFound = () => {
  const navigate = useNavigate()

  return (
    <Container component='main' maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: (theme) => theme.spacing(3),
            marginTop: (theme) => theme.spacing(3),
            borderRadius: 2,
          }}
          elevation={3}
        >
          <SentimentDissatisfiedIcon
            sx={{
              fontSize: 80,
              color: (theme) => theme.palette.error.main,
            }}
          />
          <Typography
            component='h1'
            variant='h5'
            gutterBottom
            sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}
          >
            Recipe Not Found
          </Typography>
          <Typography variant='body1' color='textSecondary' align='center'>
            {`We couldn't find the recipe you're looking for. Please check the URL or try searching
            for another recipe.`}
          </Typography>
          <Button
            type='button'
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2, textTransform: 'none' }}
            onClick={() => navigate('/my-recipes')}
          >
            Go Back to My Recipes
          </Button>
        </Paper>
      </Box>
    </Container>
  )
}

export default RecipeNotFound
