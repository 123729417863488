import React, { useState } from 'react'
import LoginForm from './login'
import SignUpForm from './sign-up'
import { Container, IconButton, Typography } from '@mui/material'
import { authService } from './auth.service'
import { useAuth } from '../auth-context'
import { Box } from '@mui/system'
import { DarkMode, LightMode } from '@mui/icons-material'
import { EmailValidator } from '../validators/email.validator'

type LoginSignUpPageProps = {
  isLightMode: boolean
  handleDarkLightModeChange: () => void
}

const LoginSignUpPage: React.FC<LoginSignUpPageProps> = ({
  isLightMode,
  handleDarkLightModeChange,
}) => {
  const [showLogin, setShowLogin] = useState(true)
  const [errors, setErrors] = useState<Record<string, { show: boolean; msg?: string }>>({})

  const { refreshUserStatus } = useAuth()

  const handleSetShowLogin = (showLogin: boolean) => {
    setShowLogin(showLogin)
    setErrors({})
  }

  const handleLoginSubmit = async (email: string, password: string) => {
    try {
      EmailValidator.parse({ email })
    } catch (error) {
      return setErrors({
        email: { show: true, msg: `This is not a valid email address` },
      })
    }

    try {
      await authService.login({ email, password, refreshUserStatus })
    } catch (error) {
      setErrors({
        email: { show: true },
        password: { show: true, msg: `We could not find a login with these details` },
      })
    }
  }

  const handleSignUpSubmit = async (email: string, password: string, confirmPassword: string) => {
    if (password !== confirmPassword) {
      return setErrors({
        confirmPassword: { show: true, msg: `Does not match password` },
      })
    }

    try {
      EmailValidator.parse({ email })
    } catch (error) {
      return setErrors({
        email: { show: true, msg: 'This is not a valid email address' },
      })
    }

    try {
      await authService.signUp({ email, password, refreshUserStatus })
    } catch (error) {
      setErrors({
        email: { show: true, msg: `This email is already in use` },
      })
    }
  }

  return (
    <Container maxWidth='xs' component='main' sx={{ pt: 10 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          sx={{ position: 'absolute', top: 0, right: 0 }}
          color='inherit'
          onClick={handleDarkLightModeChange}
        >
          {isLightMode ? <DarkMode /> : <LightMode />}
        </IconButton>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 4 }}>
        <Typography variant='h4' component='h1'>
          Welcome to Ai Chef!
        </Typography>
      </Box>
      {showLogin ? (
        <LoginForm
          setShowLogin={handleSetShowLogin}
          errors={errors}
          setErrors={setErrors}
          onSubmit={handleLoginSubmit}
        />
      ) : (
        <SignUpForm
          setShowLogin={handleSetShowLogin}
          errors={errors}
          setErrors={setErrors}
          onSubmit={handleSignUpSubmit}
        />
      )}
    </Container>
  )
}

export default LoginSignUpPage
