import React, { useState } from 'react'
import { Box, Typography, Button, Card, SxProps } from '@mui/material'
import { SkillLevel } from 'common'
import { CheckCircle, ExpandMore } from '@mui/icons-material'

const skillLevels: { level: SkillLevel; description: string }[] = [
  {
    level: 'novice',
    description: 'You are new to the kitchen. No worries, we all start somewhere.',
  },
  {
    level: 'beginner',
    description: 'You know your way around the kitchen and can follow simple recipes.',
  },
  {
    level: 'intermediate',
    description:
      'You have a solid understanding of cooking techniques and can handle complex recipes.',
  },
  {
    level: 'skilled',
    description: 'Cooking is a passion for you. You are comfortable creating your own recipes.',
  },
  {
    level: 'chef',
    description: 'You could be running your own restaurant. Your skill level is top-notch.',
  },
]

type SkillLevelSelectProps = {
  sx?: SxProps
  skillLevel: SkillLevel
  onChange: (value: SkillLevel) => void
}

const SkillLevelSelect: React.FC<SkillLevelSelectProps> = ({ sx, skillLevel, onChange }) => {
  const [expandedLevels, setExpandedLevels] = useState<SkillLevel[]>([])

  const handleLevelClick = (level: SkillLevel) => {
    setExpandedLevels((prev) =>
      prev.includes(level) ? prev.filter((l) => l !== level) : [...prev, level],
    )
  }

  const handleSelect = (level: SkillLevel) => {
    onChange(level)
    setExpandedLevels([])
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...sx }}>
      {skillLevels.map(({ level, description }) => {
        const isSelected = skillLevel === level
        const isExpanded = expandedLevels.includes(level)

        return (
          <Card
            key={level}
            sx={{
              margin: '0.5rem',
              borderRadius: '10px',
              padding: '0.5rem',
              border: isSelected ? '3px solid #3f51b5' : '1px solid #ccc',
              boxShadow: isSelected ? '0px 4px 6px rgba(0,0,0,0.1)' : 'none',
              width: '100%',
              maxWidth: '600px',
              textAlign: 'center',
            }}
          >
            <Button
              fullWidth
              onClick={() => handleLevelClick(level)}
              sx={{
                textTransform: 'none',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h6' sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}>
                {isSelected ? <CheckCircle sx={{ mr: 1, verticalAlign: 'middle' }} /> : null}
                {level.charAt(0).toUpperCase() + level.slice(1)}
              </Typography>
              <ExpandMore sx={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)' }} />
            </Button>

            {isExpanded && (
              <Box sx={{ mt: 2, textAlign: 'center' }}>
                <Typography sx={{ mb: 2 }}>{description}</Typography>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleSelect(level)}
                  sx={{ textTransform: 'none' }}
                >
                  Select
                </Button>
              </Box>
            )}
          </Card>
        )
      })}
    </Box>
  )
}

export default SkillLevelSelect
