import { Recipe, RecipeMode, RouteSSEvents, UserPreference } from 'common'
import { useState } from 'react'
import { recipeService } from '../services/recipe.service'
import { useUserSubscription } from './useUserSubscription'

export const useRecipeCreate = () => {
  const [showError, setShowError] = useState(false)

  const [recipe, setRecipe] = useState<Recipe>()
  const [isCreating, setIsCreating] = useState(false)
  const [name, setName] = useState<string>()
  const [description, setDescription] = useState<string>()
  const [ingredients, setIngredients] = useState<string[]>([])
  const [kitchenTools, setKitchenTools] = useState<string[]>([])
  const [instructions, setInstructions] = useState<string[]>([])
  const [calories, setCalories] = useState<number>()
  const [carbs, setCarbs] = useState<number>()
  const [protein, setProtein] = useState<number>()
  const [fat, setFat] = useState<number>()
  const [fiber, setFiber] = useState<number>()
  const [sugar, setSugar] = useState<number>()
  const [prepTime, setPrepTime] = useState<number>()
  const [difficulty, setDifficulty] = useState<'easy' | 'medium' | 'hard'>()
  const [url, setUrl] = useState<string>()
  const [recipeServes, setRecipeServes] = useState<number>()

  const { updateLimits } = useUserSubscription()

  const resetState = () => {
    setRecipe(undefined)
    setName(undefined)
    setDescription(undefined)
    setIngredients([])
    setKitchenTools([])
    setInstructions([])
    setCalories(undefined)
    setCarbs(undefined)
    setProtein(undefined)
    setFat(undefined)
    setFiber(undefined)
    setSugar(undefined)
    setPrepTime(undefined)
    setDifficulty(undefined)
    setUrl(undefined)
    setRecipeServes(undefined)
  }

  const onEvent = ({ eventName, data }: RouteSSEvents['/create-recipe']) => {
    if (eventName === 'recipeUrl') {
      setUrl(data.url)
    } else if (eventName === 'recipeData') {
      if (data.type === 'calories') {
        setCalories(data.calories)
      } else if (data.type === 'carbs') {
        setCarbs(data.carbs)
      } else if (data.type === 'protein') {
        setProtein(data.protein)
      } else if (data.type === 'fat') {
        setFat(data.fat)
      } else if (data.type === 'fiber') {
        setFiber(data.fiber)
      } else if (data.type === 'sugar') {
        setSugar(data.sugar)
      } else if (data.type === 'prepTime') {
        setPrepTime(data.prepTime)
      } else if (data.type === 'difficulty') {
        setDifficulty(data.difficulty)
      } else if (data.type === 'serves') {
        setRecipeServes(data.serves)
      } else if (data.type === 'name') {
        setName(data.name)
      } else if (data.type === 'description') {
        setDescription(data.description)
      } else if (data.type === 'ingredients') {
        setIngredients((prev) => [...prev, data.ingredients])
      } else if (data.type === 'kitchenTools') {
        setKitchenTools((prev) => [...prev, data.kitchenTools])
      } else if (data.type === 'instructions') {
        setInstructions((prev) => [...prev, data.instructions])
      }
    } else if (eventName === 'final') {
      const imgPreload = new Image()

      imgPreload.onload = async function () {
        // Once the image is fully loaded, update the recipe state
        setRecipe(data.recipe)
        setIsCreating(false)
        await updateLimits()
      }

      imgPreload.onerror = function () {
        // Handle error, maybe keep the temporary image or show an error message
        console.error('Failed to load the permanent image')
      }

      // Start loading the image in the background
      imgPreload.src = data.recipe.url || ''
    }
  }

  const handleCreateRecipe = async ({
    userPreference,
    recipeMode,
  }: {
    userPreference?: UserPreference
    recipeMode: RecipeMode
  }) => {
    try {
      if (!userPreference) {
        return
      }

      setIsCreating(true)

      resetState()

      const setupId = await recipeService.setup({
        recipeMode,
        userPreference,
      })

      const onApiErrorMessage = () => {
        setShowError(true)
        setIsCreating(false)
      }

      recipeService.create({
        sseSessionId: setupId,
        onEvent,
        onApiErrorMessage,
      })
    } catch (e) {
      console.log(e)
      setShowError(true)
      setIsCreating(false)
    }
  }

  return {
    handleCreateRecipe,
    resetState,
    onEvent,
    setIsCreating,
    showError,
    setShowError,
    recipe,
    isCreating,
    name,
    description,
    ingredients,
    kitchenTools,
    instructions,
    calories,
    carbs,
    protein,
    fat,
    fiber,
    sugar,
    prepTime,
    difficulty,
    url,
    recipeServes,
  }
}
