import { UserPreference } from 'common'
import { useEffect, useState } from 'react'
import { userProfileService } from './user-profile.service'

const useUserPreference = () => {
  const [isLoadingUserPreference, setIsLoadingUserPreference] = useState(false)
  const [userPreference, setUserPreference] = useState<UserPreference>()
  const [backupOfOriginalUserPreference, setBackupOfOriginalUserPreference] =
    useState<UserPreference>()

  useEffect(() => {
    const loadUserPreference = async () => {
      setIsLoadingUserPreference(true)
      try {
        const userPreference = await userProfileService.get()
        setUserPreference(userPreference)
        setBackupOfOriginalUserPreference(userPreference)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingUserPreference(false)
      }
    }

    loadUserPreference()
  }, [])

  const overrideUserPreference = (newUserPreference: UserPreference) => {
    setUserPreference(newUserPreference)
  }

  const resetUserPreference = () => {
    setUserPreference(backupOfOriginalUserPreference)
  }

  return {
    isLoadingUserPreference,
    userPreference,
    resetUserPreference,
    overrideUserPreference,
    setUserPreference,
  }
}

export default useUserPreference
