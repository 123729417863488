import React, { useContext } from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { UserSubscriptionContext } from '../../contexts/useUserSubscription'
import { AuthContext } from '../../auth-context'

type CreateRecipeButtonProps = {
  isLoading: boolean
  onClick: () => void
  disabled?: boolean
}

const CreateRecipeButton: React.FC<CreateRecipeButtonProps> = ({
  isLoading,
  onClick,
  disabled,
}) => {
  const nav = useNavigate()
  const { atRecipeLimit, currentSubscriptionType } = useContext(UserSubscriptionContext)
  const { isAdmin } = useContext(AuthContext)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 3,
        mb: 3,
        '& .MuiButton-root': {
          textTransform: 'none',
        },
      }}
    >
      {!isAdmin && atRecipeLimit ? (
        <Button
          variant='contained'
          disabled={atRecipeLimit && currentSubscriptionType === 'pro'}
          onClick={() => nav('/profile/subscription')}
          endIcon={
            isLoading ? <CircularProgress sx={{ color: 'white' }} size={20} thickness={7} /> : null
          }
        >
          {currentSubscriptionType === 'free'
            ? 'Subscribe to create more'
            : currentSubscriptionType === 'plus'
            ? 'Subscribe to Pro to create more'
            : `You're out of recipes for the month`}
        </Button>
      ) : (
        <Button
          variant='contained'
          onClick={onClick}
          disabled={isLoading || disabled}
          endIcon={
            isLoading ? <CircularProgress sx={{ color: 'white' }} size={20} thickness={7} /> : null
          }
        >
          {isLoading ? 'Creating...' : 'Create recipe'}
        </Button>
      )}
    </Box>
  )
}

export default CreateRecipeButton
