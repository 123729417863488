import React, { useState } from 'react'
import {
  Box,
  Button,
  Typography,
  FormControl,
  FormGroup,
  InputAdornment,
  IconButton,
} from '@mui/material'
import ResponsiveTextField from '../common/custom-material-ui/responsive-text-field'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import ResetPassword from './reset-password'

type Errors = Record<string, { show: boolean; msg?: string }>

type LoginProps = {
  setShowLogin: (show: boolean) => void
  errors: Errors
  setErrors: (errors: Errors) => void
  onSubmit: (email: string, password: string) => void
}

const Login: React.FC<LoginProps> = ({ setShowLogin, errors, setErrors, onSubmit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showForgotPassword, setShowForgotPassword] = useState(false)

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 8 }}>
      {!showForgotPassword ? (
        <React.Fragment>
          <Typography variant='h4' align='center'>
            Login
          </Typography>
          <FormControl
            component='form'
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit(email, password)
            }}
            fullWidth
          >
            <FormGroup>
              <Box sx={{ pt: 2, width: '100%' }}>
                <ResponsiveTextField
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value)
                    if (errors.email?.show) {
                      setErrors({})
                    }
                  }}
                  error={errors.email?.show}
                  helperText={errors.email?.msg}
                  label='Email'
                  fullWidth
                />
              </Box>
              <Box sx={{ pt: 2, width: '100%' }}>
                <ResponsiveTextField
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    if (errors.password?.show) {
                      setErrors({})
                    }
                  }}
                  error={errors.password?.show}
                  helperText={errors.password?.msg}
                  label='Password'
                  type={showPassword ? 'text' : 'password'}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge='end'>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </FormGroup>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 3,
                '& .MuiButton-root': {
                  textTransform: 'none',
                },
              }}
            >
              <Button
                variant='contained'
                type='submit'
                disabled={!email.length || !password.length || !!Object.keys(errors).length}
              >
                Login
              </Button>
              <Button variant='contained' color='primary' onClick={() => setShowLogin(false)}>
                {`Don't have an account? Sign up`}
              </Button>
            </Box>
          </FormControl>
        </React.Fragment>
      ) : (
        <ResetPassword />
      )}

      <Box sx={{ textAlign: 'center', marginTop: 2 }}>
        <Button
          sx={{ textTransform: 'none' }}
          size='small'
          color='primary'
          onClick={() => {
            setShowForgotPassword(!showForgotPassword)
          }}
        >
          {showForgotPassword ? 'Back to login' : 'Forgot Password?'}
        </Button>
      </Box>
    </Box>
  )
}

export default Login
