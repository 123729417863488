import { Recipe, ShoppingList, ShoppingListIngredient } from 'common'
import { httpService } from '../services/http.service'

export type ShoppingListWithRecipes = {
  id: string
  userId: string
  recipeIds: string[]
  ingredients: ShoppingListIngredient[]
  recipes: Recipe[]
}

const create = async ({ recipeIds }: { recipeIds: string[] }) => {
  try {
    const { shoppingList } = await httpService.post({
      url: '/create-shopping-list',
      data: {
        recipeIds,
      },
    })
    return shoppingList
  } catch (e) {
    console.error(e)
  }
}

const getShoppingListForRecipe = async ({
  recipeId,
}: {
  recipeId: string
}): Promise<ShoppingList> => {
  const { shoppingList } = await httpService.post({
    url: '/get-shopping-list-for-recipe',
    data: {
      recipeId,
    },
  })

  return shoppingList
}

const checkIfUserHasShoppingListInstance = async ({ recipeId }: { recipeId: string }) => {
  const { hasShoppingListInstance } = await httpService.post({
    url: '/does-user-have-shopping-list-instance',
    data: {
      recipeId,
    },
  })

  return hasShoppingListInstance
}

const startShopping = ({ ingredients }: { ingredients: ShoppingListIngredient[] }) => {
  const checkedIngredients = ingredients.map((i) => ({ ...i, checked: false }))
  localStorage.setItem('activeShoppingList', JSON.stringify(checkedIngredients))
  return checkedIngredients
}

const stopShopping = () => {
  localStorage.removeItem('activeShoppingList')
}

export const shoppingListService = {
  create,
  startShopping,
  stopShopping,
  getShoppingListForRecipe,
  checkIfUserHasShoppingListInstance,
}
