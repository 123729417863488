import { httpService } from './http.service'

const getPresignedUrls = async (
  files: { mimeType: string; originalName: string; fileSize: number }[],
) => {
  const { presignedUrls } = await httpService.post({
    url: '/get-presigned-urls',
    data: {
      files,
    },
  })

  return presignedUrls
}

const uploadToS3 = async (file: File, presignedUrl: string) => {
  const response = await fetch(presignedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': file.type,
    },
  })

  if (!response.ok) {
    throw new Error('Failed to upload file')
  }
}

export const fileService = {
  getPresignedUrls,
  uploadToS3,
}
